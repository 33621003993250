// ================================
// Utilities: Alignments
// ================================

$fds-alignments: (
  inherit: inherit,
  left: left,
  center: center,
  right: right
) !default;

@include fds-set-selectors($fds-alignments, 'text-align', 'fds-align--');
