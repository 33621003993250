.fds-snackbar {
  position: absolute;
  width: 100%;
  bottom: 0;
  opacity: 0;
  transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.fds-snackbar--show {
  opacity: 1;

  @media (max-width: map-get($fds-breakpoints-max, 'fds-sm')) {
    bottom: fds-rem(30px);
  }

  @media (min-width: map-get($fds-breakpoints, 'fds-md')) {
    bottom: fds-rem(60px);
  }
}

.fds-snackbar__content-wrapper {
  background-color: var(--fds-color--primary);
  border: 1px solid var(--fds-color--white);
  color: var(--fds-color--white);
  border-radius: fds-rem(3px);
  padding: fds-rem(20px 0);
  box-shadow: $fds-elevation__box-shadow--layer2;
}

.fds-snackbar__content-wrapper-inner {
  @media (max-width: map-get($fds-breakpoints-max, 'fds-xs')) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (max-width: map-get($fds-breakpoints-max, 'fds-sm')) and (min-width: map-get($fds-breakpoints, 'fds-sm')) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  @media (max-width: map-get($fds-breakpoints-max, 'fds-md')) and (min-width: map-get($fds-breakpoints, 'fds-md')) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  @media (max-width: map-get($fds-breakpoints-max, 'fds-lg')) and (min-width: map-get($fds-breakpoints, 'fds-lg')) {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  @media (min-width: map-get($fds-breakpoints, 'fds-xl')) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

.fds-snackbar__text {
  @include fds-get-component-typeset('snackbar');
  padding-left: fds-rem(10px);
}

.fds-snackbar__button {
  font-size: fds-rem(12px);
  letter-spacing: fds-rem(1px);
  line-height: fds-rem(21px);
  padding-right: fds-rem(10px);

  &.fds-snackbar__button--focused {
    &::before,
    & .fds-snackbar__button-text {
      outline: fds-rem(1px) solid var(--fds-color--white);
      outline-offset: fds-rem(3px);
    }
  }
}

.fds-snackbar__type--timed {
  & .fds-snackbar__text {
    width: auto;

    @media (max-width: map-get($fds-breakpoints-max, 'fds-xs')) {
      grid-column-end: span 4;
    }

    @media (max-width: map-get($fds-breakpoints-max, 'fds-sm')) and (min-width: map-get($fds-breakpoints, 'fds-sm')) {
      grid-column-end: span 8;
    }

    @media (max-width: map-get($fds-breakpoints-max, 'fds-md')) and (min-width: map-get($fds-breakpoints, 'fds-md')) {
      grid-column-end: span 12;
    }

    @media (max-width: map-get($fds-breakpoints-max, 'fds-lg')) and (min-width: map-get($fds-breakpoints, 'fds-lg')) {
      grid-column-end: span 10;
    }

    @media (min-width: map-get($fds-breakpoints, 'fds-xl')) {
      grid-column-end: span 8;
    }
  }
}

.fds-snackbar__type--dismissible {
  & .fds-snackbar__text {
    @media (max-width: map-get($fds-breakpoints-max, 'fds-xs')) {
      grid-column-end: span 3;
    }

    @media (max-width: map-get($fds-breakpoints-max, 'fds-md')) and (min-width: map-get($fds-breakpoints, 'fds-md')) {
      grid-column-end: span 11;
    }
  }
}

.fds-snackbar__type--dismissible,
.fds-snackbar__type--actionable {
  & .fds-snackbar__text {
    width: auto;

    @media (max-width: map-get($fds-breakpoints-max, 'fds-xs')) and (min-width: map-get($fds-breakpoints, 'fds-xs')) {
      grid-column-end: span 3;
    }

    @media (max-width: map-get($fds-breakpoints-max, 'fds-sm')) and (min-width: map-get($fds-breakpoints, 'fds-sm')) {
      grid-column-end: span 7;
    }

    @media (max-width: map-get($fds-breakpoints-max, 'fds-lg')) and (min-width: map-get($fds-breakpoints, 'fds-lg')) {
      grid-column-end: span 9;
    }

    @media (min-width: map-get($fds-breakpoints, 'fds-xl')) {
      grid-column-end: span 7;
    }
  }

  & .fds-snackbar__button {
    text-align: right;
    width: auto;
    grid-column-end: span 1;
  }
}

.fds-snackbar__type--actionable {
  & .fds-snackbar__text {
    @media (max-width: map-get($fds-breakpoints-max, 'fds-xxs')) {
      grid-column-end: span 4;
    }

    @media (max-width: map-get($fds-breakpoints-max, 'fds-md')) and (min-width: map-get($fds-breakpoints, 'fds-md')) {
      grid-column-end: span 10;
    }
  }

  & .fds-snackbar__button {
    text-transform: uppercase;

    @media (max-width: map-get($fds-breakpoints-max, 'fds-xxs')) {
      grid-column-end: span 4;
      text-align: center;
    }

    @media (max-width: map-get($fds-breakpoints-max, 'fds-md')) and (min-width: map-get($fds-breakpoints, 'fds-md')) {
      grid-column-end: span 2;
    }
  }
}
