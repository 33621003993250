.fds-floating-action-button {
  position: fixed;
  right: 0;
  left: auto;
  bottom: fds-rem(16px);
  z-index: 2;
}

.fds-floating-action-button__wrapper {
  position: relative;
  width: fds-rem(240px);
  margin-top: fds-rem(25px);
  padding-top: fds-rem(5px);

  @media (max-width: map-get($fds-breakpoints-max, 'fds-sm')) {
    right: fds-rem(20px);
  }

  @media (min-width: map-get($fds-breakpoints, 'fds-md')) {
    right: fds-rem(100px);
  }
}

.fds-floating-action-button__button {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  position: relative;
  min-width: fds-rem(40px);
  min-height: fds-rem(40px);
  opacity: 0;
  transition: all 0.3s ease-in-out;

  // remove flash on iOS mobile as accessibility indicators are customized in this CSS
  -webkit-tap-highlight-color: var(--fds-color--tertiary-0);

  // prevent button elements from shifting in IE11
  top: 0;
  left: fds-rem(200px);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:not(.fds-floating-action-button__button-to-top) {
    opacity: 1;
  }

  & .fds-floating-action-button__button--before {
    content: '';
    border-radius: fds-rem(20px);
    border: 1px solid var(--fds-color--white);
    background-color: var(--fds-color--primary);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: fds-rem(40px);
    will-change: transform, background-color, border-color;
    box-shadow: $fds-elevation__box-shadow--layer2;
    transition: all 0.3s ease-in-out;
  }

  & .fds-floating-action-button__button-content {
    @include fds-get-component-typeset('floating-action-button');

    color: var(--fds-color--white);
    position: relative;
    font-weight: 400;
    height: fds-rem(16px);
    text-transform: capitalize;
    text-align: center;
    width: 100%;
    pointer-events: none; // Fix for IE11 :active not firing on link click

    &::before {
      font-family: 'FordIcons' !important;
      line-height: 1;
      font-size: fds-rem(16px);
      color: var(--fds-color--white);
      padding: 0 fds-rem(12px);
    }
  }

  & .fds-floating-action-button__button-text {
    display: none;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }
}

// Disabled
.fds-floating-action-button--disabled {
  cursor: not-allowed;

  & .fds-floating-action-button__button--before {
    background-color: var(--fds-color--disabled3);
    box-shadow: none;
  }
}

// Focus
.fds-floating-action-button--focused {
  &::before {
    outline: 1px solid var(--fds-color--primary);
    outline-offset: fds-rem(19px);
  }

  &::after {
    outline: 1px solid var(--fds-color--white);
    outline-offset: fds-rem(20px);
  }
}

// Hover and Active
.fds-floating-action-button--hover,
.fds-floating-action-button--active {
  &:not(.fds-floating-action-button--disabled) {
    & .fds-floating-action-button__button-text {
      display: inline;
    }
  }
}

// Hoverfwef
.fds-floating-action-button--hover:not(.fds-floating-action-button--disabled) {
  & .fds-floating-action-button__button--before {
    background-color: var(--fds-color--secondary);
    transform: scale(1.07);
    box-shadow: $fds-elevation__box-shadow--layer3;
  }

  & .fds-floating-action-button__button-content {
    padding: fds-rem(12px 23px 11px);
    height: auto;

    &::before {
      padding: fds-rem(0 15px 0 0);
    }
  }
}

// Active
.fds-floating-action-button--active {
  &:not(.fds-floating-action-button--disabled) {
    & .fds-floating-action-button__button {
      & .fds-floating-action-button__button--before {
        background-color: var(--fds-color--primary);
        transform: scale(1);
        box-shadow: $fds-elevation__box-shadow--layer1;
      }
    }
  }
}
