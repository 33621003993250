// ================================
// Reset
// ================================

// The FDS assumes {box-sizing: border-box}
*,
*::before,
*::after {
  box-sizing: inherit;
}

// Additional resets:
body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
table {
  margin: 0;
}

ul,
ol {
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

select:disabled {
  opacity: 1;
}

::-moz-placeholder{opacity: 1;}

i,
em {
  font-style: italic;
}
