fmc-popover {
  &[brand='lincoln'] {
    [slot='heading'],
    [slot='body'] * {
      color: var(--fds-color--white)
    }

    [slot='body'] a:focus-visible {
      outline-color: var(--fds-color--white);
    }
  }
}
