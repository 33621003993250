.fmc-slider {
  --slider-background: transparent;
  --label-color: var(--fds-color--gray3);
  --thumb-diameter: #{fds-rem(32px)};
  --thumb-color: var(--fds-color--primary);
  --track-height: #{fds-rem(3px)};
  --track-color: var(--fds-color--primary);
  --margin-tb: #{fds-rem(8px)};
  --webkit-thumb-margin-tb: calc((var(--thumb-diameter) - var(--track-height)) / 2);

  %outline {
    outline: var(--fds-outline);
    outline-offset: var(--fds-outline-offset);
  }

  @mixin thumb {
    height: var(--thumb-diameter);
    width: var(--thumb-diameter);
    border-radius: 100%;
    background: var(--thumb-color);
    border: none;
    cursor: pointer;
  }

  @mixin track {
    width: 100%;
    height: var(--track-height);
    background: var(--track-color);
    border: none;
    border-radius: calc(var(--track-height) / 2);
    cursor: pointer;
  }

  // Remove native slider/range appearance -- Hides the slider etc. so that custom slider can be made
  input[type="range"] {
    -webkit-appearance: none; /* stylelint-disable-line */
    width: 100%; /* Specific width is required for Firefox. */
    background: var(--slider-background); /* Otherwise white in Chrome */
    margin: var(--margin-tb) 0;

    &::-webkit-slider-thumb {
      -webkit-appearance: none; /* stylelint-disable-line */
      @include thumb;
      margin-top: calc(-1 * var(--webkit-thumb-margin-tb));
    }

    &::-webkit-slider-runnable-track {
      @include track;
      margin: calc(var(--webkit-thumb-margin-tb) - var(--margin-tb)) 0;
    }

    &:focus-visible::-webkit-slider-runnable-track {
      @extend %outline;
    }

    &::-moz-range-thumb {
      @include thumb;
    }

    &::-moz-range-track {
      @include track;
    }

    &:focus-visible::-moz-range-track {
      @extend %outline;
    }

    &::-ms-thumb {
      @include thumb;
    }

    &::-ms-track {
      @include track;
      background: transparent;
      color: transparent;
    }

    &::-ms-fill-lower {
      background: var(--track-color);
    }

    &::-ms-fill-upper {
      background: var(--track-color);
    }

    &:focus::-ms-track {
      @extend %outline;
    }
  }

  input[type="range"]:focus {
    outline: none;
  }

  label {
    font-size: fds-rem(12px);
    color: var(--label-color);
  }
}
