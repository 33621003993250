// ================================
// Tools: Set brand
// ================================

// Sets brand specific styles.
// Currently only used for lincoln

@mixin fds-set-brand($brand) {
  [data-brand="#{$brand}"] & {
    @content;
  }
}
