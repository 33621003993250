// ================================
// Icons
// ================================

$fds-font--ford-icons: 'FordIcons' !default;
$fds-font--ford-icons__play-arrow: '\e900' !default;
$fds-font--ford-icons__unmask: '\e90a' !default;
$fds-font--ford-icons__mask: '\e908' !default;
$fds-font--ford-icons__success: '\e903' !default;
$fds-font--ford-icons__error: '\e90b' !default;
$fds-font--ford-icons__clear: '\e909' !default;
$fds-font--ford-icons__tick: '\e91d' !default;
$fds-font--ford-icons__chevron-left: '\e90c' !default;
$fds-font--ford-icons__chevron-right: '\e90d' !default;
$fds-font--ford-icons__chevron-up: '\e907' !default;
$fds-font--ford-icons__chevron-down: '\e906' !default;
$fds-font--ford-icons__edit: '\e90e' !default;
$fds-font--ford-icons__info-filled: '\e901' !default;
$fds-font--ford-icons__warning-filled: '\e990' !default;
$fds-font--ford-icons__check-filled: '\e90f' !default;
$fds-font--ford-icons__dark-car-rear: '\e910' !default;
$fds-font--ford-icons__dark-car-side: '\e911' !default;
$fds-font--ford-icons__dark-seat: '\e912' !default;
$fds-font--ford-icons__search: '\e913' !default;
$fds-font--ford-icons__chat: '\e914' !default;
$fds-font--ford-icons__ellipsis: '\e915' !default;
$fds-font--ford-icons__minus: '\e916' !default;
$fds-font--ford-icons__plus: '\e917' !default;
$fds-font--ford-icons__info-outlined: '\e905' !default;
$fds-font--ford-icons__long-arrow-back: '\e918' !default;
$fds-font--ford-icons__long-arrow-forward: '\e919' !default;
$fds-font--ford-icons__chevron-filled-right: '\e91a' !default;
$fds-font--ford-icons__chevron-filled-left: '\e902' !default;
