.fds-color__bg--light {
  & ul.fds-list {
    list-style-image: url('#{$fds-ford-icon-directory}/list/light-unordered-level-1.svg');
  }

  & .fds-list ul.fds-list {
    list-style-image: url('#{$fds-ford-icon-directory}/list/light-unordered-level-2.svg');
  }

  & .fds-list .fds-list ul.fds-list {
    list-style-image: url('#{$fds-ford-icon-directory}/list/light-unordered-level-3.svg');
  }
}

.fds-color__bg--dark {
  & ul.fds-list {
    list-style-image: url('#{$fds-ford-icon-directory}/list/dark-unordered-level-1.svg');
  }

  & .fds-list ul.fds-list {
    list-style-image: url('#{$fds-ford-icon-directory}/list/dark-unordered-level-2.svg');
  }

  & .fds-list .fds-list ul.fds-list {
    list-style-image: url('#{$fds-ford-icon-directory}/list/dark-unordered-level-3.svg');
  }
}

ol.fds-list {
  list-style-type: decimal;
}

.fds-list ol.fds-list {
  list-style-type: lower-alpha;
}

.fds-list .fds-list ol.fds-list {
  list-style-type: lower-roman;
}

.fds-list li {
  @extend %fmc-type--body1;
  margin-left: fds-rem(33px);
  padding-bottom: fds-rem(10px);
  position: relative;
  left: fds-rem(2px);
  top: fds-rem(2px);
}
