// ================================
// Utilities: Position
// ================================

$fds-position: (
  sticky: sticky,
  fixed: fixed,
  absolute: absolute,
  relative: relative,
  static: static
) !default;

@include fds-set-selectors($fds-position, 'position', 'fds-position--');
