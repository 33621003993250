// ================================
// Utilities: Margin
// ================================

// TODO make these root variables
$fds-margins: (
  auto: auto,
  0: 0,
  1: fds-rem(10px),
  2: fds-rem(20px),
  3: fds-rem(40px),
  4: fds-rem(80px)
) !default;

@each $label, $value in $fds-margins {
  .fds-m--t-#{$label} {
    margin-top: $value;
  }
}

@each $label, $value in $fds-margins {
  .fds-m--b-#{$label} {
    margin-bottom: $value;
  }
}

@each $label, $value in $fds-margins {
  .fds-m--l-#{$label} {
    margin-left: $value;
  }
}

@each $label, $value in $fds-margins {
  .fds-m--r-#{$label} {
    margin-right: $value;
  }
}

@each $label, $value in $fds-margins {
  .fds-m--x-#{$label} {
    margin-left: $value;
    margin-right: $value;
  }
}

@each $label, $value in $fds-margins {
  .fds-m--y-#{$label} {
    margin-top: $value;
    margin-bottom: $value;
  }
}

// --------------------------------
// Responsive Margin
// --------------------------------

@each $breakpoint in map-keys($fds-breakpoints) {
  @include media(#{'>=' + $breakpoint}) {
    @each $label, $value in $fds-margins {
      .#{$breakpoint}\:fds-m--t-#{$label} {
        margin-top: $value;
      }
    }

    @each $label, $value in $fds-margins {
      .#{$breakpoint}\:fds-m--b-#{$label} {
        margin-bottom: $value;
      }
    }

    @each $label, $value in $fds-margins {
      .#{$breakpoint}\:fds-m--l-#{$label} {
        margin-left: $value;
      }
    }

    @each $label, $value in $fds-margins {
      .#{$breakpoint}\:fds-m--r-#{$label} {
        margin-right: $value;
      }
    }

    @each $label, $value in $fds-margins {
      .#{$breakpoint}\:fds-m--x-#{$label} {
        margin-left: $value;
        margin-right: $value;
      }
    }

    @each $label, $value in $fds-margins {
      .#{$breakpoint}\:fds-m--y-#{$label} {
        margin-top: $value;
        margin-bottom: $value;
      }
    }
  }
}
