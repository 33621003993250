// ================================
// Tools: Set class selectors
// ================================

// Returns a list of responsive utility classes

@function is-map($map){
  @return type-of($map) == 'map';
}

@mixin fds-set-selectors($map, $property: false, $prepend: '') {
  @if is-map($map) {
    @if $property != false {
      // default
      @each $label, $value in $map {
        .#{$prepend}#{$label} {
          #{$property}: $value;
        }
      }

      // responsive
      @each $breakpoint in map-keys($fds-breakpoints) {
        @include media(#{'>=' + $breakpoint}) {
          @each $label, $value in $map {
            .#{$breakpoint}\:#{$prepend}#{$label} {
              #{$property}: $value;
            }
          }
        }
      }
    } @else {
      @warn "A property label is required to generate utility classes! For example 'text-align', 'display', 'position', etc... ";
    }
  } @else {
    @warn "Couldn't find a SCSS map named `#{$map}`.";
  }
}
