// ================================
// Backwards Compatibility Type
// ================================

.fds-type--display1 {
  @extend %fmc-type--heading1;
}

.fds-type--display2 {
  @extend %fmc-type--heading2;
}

.fds-type--display3 {
  @extend %fmc-type--heading3;
}

.fds-type--title1 {
  @extend %fmc-type--heading4;
}

.fds-type--title2 {
  @extend %fmc-type--heading5;
}

.fds-type--title3 {
  @extend %fmc-type--heading6;
}

.fds-type--button {
  @extend %fmc-type--content1;
}

.fds-type--caption {
  @extend %fmc-type--content2;
}

// this is an anomoly with class structure
// it doesn't exist in Lincoln and there are
// error styles handled with theming
.fds-type--error-message {
  font-size: fds-rem(12px);
  line-height: 1.75;
  letter-spacing: fds-rem(1px);
  font-weight: 400;
}

.fds-type--display1,
.fds-type--display2,
.fds-type--display3,
.fds-type--title1,
.fds-type--title2,
.fds-type--title3,
.fds-type--content2 {
  & a,
  & a:link,
  & a:active {
    text-decoration: underline;
  }
}

