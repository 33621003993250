.fmc-billboard {
  --billboard-height: 100vh; // TODO: should this really be 67...?
  --justify-content: flex-start;
  --align-items: flex-start;
  --color--white: #{fds-color2rgb($fds-color--white)};
  --color--tertiary: #{fds-color2rgb($fds-color--tertiary)};
  --color--black: #{fds-color2rgb($fds-color--black)};
  --scrim-color: var(--color--white);
  --scrim-start-opacity: 0;
  --scrim-end-opacity: 1;
  --scrim-direction: top;
  --transform: none;
  --content-max-width: auto;
  --content-max-height: auto;

  @include fds-set-brand(lincoln) {
    --scrim-start-opacity: 0.06;
  }

  width: 100%; // It's not spelled out in the guidelines, but this is a reasonable assumption, right?
  height: var(--billboard-height);
  position: relative;
  display: flex;
  justify-content: var(--justify-content);
  align-items: var(--align-items);
  overflow: clip;

  .fmc-billboard__image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;

    transform: var(--transform);
  }

  &.fmc-billboard--split-image-top {
    --transform: translate(0, -50%);
    --content-max-height: 50%;
  }

  &.fmc-billboard--split-image-right {
    --transform: translate(50%, 0);
    --content-max-width: 50%;
  }

  &.fmc-billboard--split-image-bottom {
    --transform: translate(0, 50%);
    --content-max-height: 50%;
  }

  &.fmc-billboard--split-image-left {
    --transform: translate(-50%, 0);
    --content-max-width: 50%;
  }

  .fmc-billboard__scrim {
    --scrim-background: linear-gradient(
      to var(--scrim-direction),
      rgba(var(--scrim-color), var(--scrim-start-opacity)),
      rgba(var(--scrim-color), var(--scrim-end-opacity))
    );  // This must be defined in here to have an effect

    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--scrim-background);
  }

  &.fmc-billboard--height-100vh {
    --billboard-height: 100vh;
  }

  &.fmc-billboard--height-67vh {
    --billboard-height: 67vh;
  }

  &.fmc-billboard--height-33vh {
    --billboard-height: 33vh;
  }

  // Content justification (TODO: investigate how to put these on the content)
  &.fmc-billboard--justify-start {
    --justify-content: flex-start;
  }

  &.fmc-billboard--justify-center {
    --justify-content: center;
  }

  &.fmc-billboard--justify-end {
    --justify-content: flex-end;
  }

  &.fmc-billboard--align-start {
    --align-items: flex-start;
  }

  &.fmc-billboard--align-center {
    --align-items: center;
  }

  &.fmc-billboard--align-end {
    --align-items: flex-end;
  }

  // Scrim location
  .fmc-billboard--scrim-top {
    --scrim-direction: top;
  }

  .fmc-billboard--scrim-bottom {
    --scrim-direction: bottom;
  }

  .fmc-billboard--scrim-left {
    --scrim-direction: left;
  }

  .fmc-billboard--scrim-right {
    --scrim-direction: right;
  }

  .fmc-billboard--scrim-full {
    --scrim-background: rgba(var(--scrim-color), 0.5);
  }

  // Scrim color
  .fmc-billboard--scrim-white,
  .fmc-billboard--scrim-white ~ .fmc-billboard__content {
    --scrim-color: var(--color--white);
  }

  .fmc-billboard--scrim-dark,
  .fmc-billboard--scrim-dark ~ .fmc-billboard__content {
    --scrim-color: var(--color--tertiary);
  }

  .fmc-billboard--scrim-black,
  .fmc-billboard--scrim-black ~ .fmc-billboard__content {
    --scrim-color: var(--color--black);
  }

  .fmc-billboard__content {
    position: relative;
    max-width: var(--content-max-width);
    max-height: var(--content-max-height);
  }
}
