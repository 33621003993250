// ================================
// Container
// ================================

.fds-flex__container,
%fds-flex__container {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: fds-rem($fds-flex__container-gutter--small);
  padding-right: fds-rem($fds-flex__container-gutter--small);

  // @include media('>=fds-lg') {
  //   padding-left: fds-rem($fds-flex__container-gutter--large * 2);
  //   padding-right: fds-rem($fds-flex__container-gutter--large * 2);
  // }
}

@each $size, $width in $fds-flex__containers {
  .fds-flex__container--#{$size},
  %fds-flex__container--#{$size} {
    @extend %fds-flex__container;
    max-width: $width;
  }
}

// .gr\:fds-flex__container--flush {
//   @include media('>=fds-xl') {
//     max-width: none;
//   }
// }
