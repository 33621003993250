.fds-carousel-indicator {
  display: flex;
  justify-content: center;
}

.fds-carousel-indicator-wrapper {
  display: flex;
  align-items: center;
}

/*
  Directional Button Styles
*/
.fds-carousel-indicator__directional-button {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  position: relative;
  width: fds-rem(40px);
  height: fds-rem(40px);

  // remove flash on iOS mobile as accessibility indicators are customized in this CSS
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  // prevent button elements from shifting in IE11
  top: 0;
  left: 0;

  &::before {
    content: '';
    border-radius: fds-rem(3px);
    background-color: var(--fds-color--primary);
    box-shadow: $fds-elevation__box-shadow--layer2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    will-change: transform, background-color;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }

  &:not(.fds-carousel-indicator__button--disabled) {
    &:hover::before,
    &:active::before,
    &.fds-carousel-indicator__button--active::before {
      background-color: var(--fds-color--secondary);
    }

    &:hover::before {
      transform: scale(1.07);
      box-shadow: $fds-elevation__box-shadow--layer3;
    }

    &:active::before,
    &.fds-carousel-indicator__button--active::before {
      transform: scale(1);
      box-shadow: $fds-elevation__box-shadow--layer1;
    }
  }

  &.fds-carousel-indicator__button--disabled {
    cursor: not-allowed;

    &::before {
      background-color: var(--fds-color--disabled3);
      box-shadow: none;
    }
  }

  & .fds-carousel-indicator__directional-button__text {
    color: var(--fds-color--white);
    position: relative;
    text-align: center;
    width: 100%;
    pointer-events: none; // Fix for IE11 :active not firing on link click

    &::before {
      font-family: $fds-font--ford-icons !important;
    }
  }

  &.fds-carousel-indicator__directional-button--next {
    & .fds-carousel-indicator__directional-button__text::before {
      content: $fds-font--ford-icons__chevron-right;
    }
  }

  &.fds-carousel-indicator__directional-button--previous {
    & .fds-carousel-indicator__directional-button__text::before {
      content: $fds-font--ford-icons__chevron-left;
    }
  }
}

/*
  Pagination Styles
*/
.fds-carousel-indicator__pagination-wrapper {
  align-self: center;
}

.fds-carousel-indicator__pagination-numbers {
  @extend %fmc-type--body1;
  margin: fds-rem(auto 20px);
  display: flex;
  align-items: center;
}

.fds-carousel-indicator__pagination-text {
  @extend %fmc-type--body1;

  margin: fds-rem(auto 20px);
  display: flex;
  align-items: center;
}

/*
  Pancake Styles
*/
.fds-carousel-indicator__button--active {
  & .fds-carousel-indicator__pancake {
    background-color: var(--fds-color--primary);
  }
}

.fds-carousel-indicator__pancake-buttons {
  display: flex;
  list-style: none;
  padding: fds-rem(0 15px);
  line-height: 0;
}

.fds-carousel-indicator__pancake-button {
  padding: fds-rem(10px 0);
}

.fds-carousel-indicator__pancake {
  border-radius: fds-rem(2px);
  display: block;
  width: fds-rem(32px);
  height: fds-rem(3px);
  background-color: var(--fds-color--gray2);
  margin: fds-rem(0 5px);
}

/*
  Focus Styles
*/
.fds-using-keyboard {
  & .fds-carousel-indicator__directional-button:focus,
  & .fds-carousel-indicator__pancake-button:focus,
  & .fds-carousel-indicator__pagination-text:focus {
    outline: 1px solid var(--fds-color--primary);
  }

  & .fds-carousel-indicator__directional-button:focus {
    outline-offset: fds-rem(5px);
  }

  & .fds-carousel-indicator__pancake-button:focus {
    outline-offset: fds-rem(1px);
  }

  & .fds-carousel-indicator__pagination-text:focus {
    outline-offset: fds-rem(10px);
  }
}

/*
  Carousel Styles
*/
.fds-carousel-indicator__carousel {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;

  @media only screen
    and (max-height: map-get($fds-breakpoints-landscape-max, 'handheld'))
    and (max-width: map-get($fds-breakpoints, 'fds-md')) {
    padding: 0;

    & .fds-layout-grid {
      padding: 0;
    }

    & .fds-layout-grid__inner {
      display: block;
    }
  }
}

.fds-carousel-indicator__carousel__no-animate {
  & .fds-carousel-indicator__carousel-slides {
    transition: none;
  }
}

.fds-carousel-indicator__carousel-slides-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.fds-carousel-indicator__carousel-slides {
  height: 100%;
  position: relative;
  display: flex;

  transform: translate3d(0, 0, 0);
  transition: all 0.3s ease-in-out;
}

.fds-carousel-indicator__carousel-slide {
  list-style: none;
  position: relative;
  height: 100%;
}

.fds-carousel-indicator__carousel-slide__image {
  height: auto;
  width: 100%;
  display: block;
}

/*
  Live Region
*/
.fds-carousel-indicator_live-region {
  font-size: 0;
  height: 0;
}
