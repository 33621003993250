// ================================
// Tools: Get em
// ================================

/**
 * Convert values into em units.
 * @param {string} $pixels - Unit value. Can only be single.
 * @param {string} $context [$fds-base-multiplier-em] - Set unit base, or defaults to defined value in settings.
 *
 * @example scss - Use with single or multiple units
 *  font-size: fds-em(20px);
 *
 * @example scss - Use with a base unit size
 *  font-size: fds-em(20px, 12px);
 */

// Function to get em value
@function fds-em($pixels, $context: $fds-base-multiplier-em) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}
