%fmc-divider {
  --divider-size: 1px;
  --divider-color: var(--fds-color--black-40);
  --divider-margin: #{fds-rem(16px)};
  --divider-full-offset: calc(var(--divider-size) * -1);
  --divider-full-pos: calc(var(--divider-margin) * -1);

  border: 0 solid var(--divider-color);
  box-sizing: border-box;

  &::before, &::after {
    position: absolute;
    background-color: var(--divider-color);
  }
}

*[class*='fmc-divider'] {
  @extend %fmc-divider;
}

hr {
  &.fmc-divider--horizontal,
  &.fmc-divider--horizontal-full {
    height: var(--divider-size);
    grid-column: 1 / -1;
  }

  &.fmc-divider--horizontal {
    width: 100%;
  }

  &.fmc-divider--horizontal-full {
    width: calc(100% + var(--divider-margin) * 2);
    transform: translateX(calc(var(--divider-margin) * -1));

    &::before, &::after {
      content: unset;
    }
  }

  &.fmc-divider--vertical,
  &.fmc-divider--vertical-full {
    width: var(--divider-size);
    align-self: center;
  }

  &.fmc-divider--vertical {
    height: 100%;
  }

  &.fmc-divider--vertical-full {
    height: calc(100% + var(--divider-margin) * 2);

    &::before, &::after {
      content: unset;
    }
  }

}

.fmc-divider--bottom-full,
.fmc-divider--top-full,
.fmc-divider--right-full,
.fmc-divider--left-full {
  position: relative;

  &::before, &::after {
    content: '';
  }
}

.fmc-divider--bottom-full,
.fmc-divider--top-full {
  &::before, &::after {
    width: var(--divider-margin);
    height: var(--divider-size);
  }

  &::before {
    left: var(--divider-full-pos);
  }

  &::after {
    right: var(--divider-full-pos);
  }
}

.fmc-divider--horizontal,
.fmc-divider--horizontal-full,
.fmc-divider--bottom,
.fmc-divider--bottom-full {
  border-bottom-width: var(--divider-size);

  &::before, &::after {
    bottom: var(--divider-full-offset);
  }
}

.fmc-divider--top,
.fmc-divider--top-full {
  border-top-width: var(--divider-size);

  &::before, &::after {
    top: var(--divider-full-offset);
  }
}

.fmc-divider--right,
.fmc-divider--left,
.fmc-divider--right-full,
.fmc-divider--left-full {
  &::before, &::after {
    width: var(--divider-size);
    height: var(--divider-margin);
  }

  &::before {
    top: var(--divider-full-pos);
  }

  &::after {
    bottom: var(--divider-full-pos);
  }
}

.fmc-divider--vertical,
.fmc-divider--vertical-full,
.fmc-divider--right,
.fmc-divider--right-full {
  border-right-width: var(--divider-size);

  &::before, &::after {
    right: var(--divider-full-offset);
  }
}

.fmc-divider--left,
.fmc-divider--left-full {
  border-left-width: var(--divider-size);

  &::before, &::after {
    left: var(--divider-full-offset);
  }
}
