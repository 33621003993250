// ================================
// Tools: Set Contrast Styles
// ================================

// Sets styles for dark theme

@mixin fds-set-dark() {
  &[data-dark] {
    @content;
  }
}
