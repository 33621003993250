.fmc-activity, .fmc-activity-circle {
  --indicator-color: var(--fds-color--gray2);
  --activity-line-color: var(--fds-color--primary);
  --circle-diameter: #{fds-rem(80px)};

  @include fds-set-brand(lincoln) {
    --indicator-color: var(--fds-color--gray4);
    --activity-line-color: var(--fds-color--secondary);
    --circle-diameter: #{fds-rem(60px)};
  }
}

.fmc-activity {
  --line-weight: 0.1rem;
  --loader-weight: 0.3rem;
  --indeterminate-length: 50%;

  position: relative;

  &::before {
    position: absolute;
    content: "";
    top: calc((var(--loader-weight) - var(--line-weight)) / 2);
    right: 0;
    left: 0;
    bottom: 0;
    height: var(--line-weight);
    border-radius: var(--line-weight);
    background-color: var(--indicator-color);
  }

  .fmc-activity__progress {
    position: relative;
    height: var(--loader-weight);
    border-radius: var(--loader-weight);
    overflow: hidden;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: var(--indeterminate-length);
      height: var(--loader-weight);
      background-color: var(--activity-line-color);
      animation-name: linearindetermiate;
      animation-iteration-count: infinite;
      animation-duration: 2.5s;
      animation-timing-function: linear;
    }
  }

  .fmc-activity__progress--percent {
    &::before {
      width: 100%;
      animation: none;
      transition: width var(--fmc-transition-duration) var(--fmc-transition-timing);
    }
  }

  .fmc-activity__text {
    text-align: center;
    margin-top: fds-rem(8px);
    line-height: fds-rem(16px);
  }

  @keyframes linearindetermiate {
    0% {
      left: calc(var(--indeterminate-length) * -1);
    }

    100% {
      left: 100%;
    }
  }
}

.fmc-activity-circle {
  --line-weight: 0.1rem;
  --loader-weight: 0.3rem;

  position: relative;
  width: var(--circle-diameter);
  height: var(--circle-diameter);
  margin: auto;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    margin-left: calc(var(--circle-diameter) / -2);
    border-radius: var(--circle-diameter);
    border: var(--line-weight) solid var(--indicator-color);
  }

  .fmc-activity-circle__progress {
    --offset: calc((var(--loader-weight) - var(--line-weight)));

    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: calc(var(--circle-diameter) + var(--offset));
      height: calc(var(--circle-diameter) + var(--offset));
      left: 50%;
      top: calc(var(--offset) / -2);
      margin-left: calc((var(--circle-diameter) + var(--offset)) / -2);
      border-radius: var(--circle-diameter);
      border: var(--loader-weight) solid transparent;
      border-top: var(--loader-weight) solid var(--activity-line-color);
      transform: rotate(0deg);
      animation-name: circularindetermiate;
      animation-iteration-count: infinite;
      animation-duration: 2.5s;
      animation-timing-function: linear;
    }

    @keyframes circularindetermiate {
      100% {
        transform: rotate(360deg);
      }
    }

  }

}
