// ================================
// Utilities: Gutter
// ================================

$fds-gutter: (
  xl: 24px,
  lg: 24px,
  md: 24px,
  sm: 16px,
  xs: 16px
) !default;

@each $label, $value in $fds-gutter {
  .fds-vg-#{$label} {
    padding-top: $value;
    padding-bottom: $value;
  }
}

// --------------------------------
// Responsive Gutter
// --------------------------------

@each $breakpoint in map-keys($fds-breakpoints) {
  @include media(#{'>=' + $breakpoint}) {
    @each $label, $value in $fds-gutter {
      $gutter: calc(#{$value} / 2);

      .#{$breakpoint}\:fds-vg-#{$label} {
        padding-top: $value;
        padding-bottom: $value;

        &[class*='fds-flex__col-'] {
          padding-top: $gutter;
          padding-bottom: $gutter;
        }
      }
    }
  }
}
