.fds-full-screen-image-gallery__overlay {
  display: none;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background-color: var(--fds-color--white);
  right: 0;
  padding-top: fds-rem(54px);

  & .fds-layout-grid__inner {
    row-gap: 0;
  }

  @media only screen
    and (max-height: map-get($fds-breakpoints-landscape-max, 'handheld'))
    and (max-width: map-get($fds-breakpoints, 'fds-md'))
    and (orientation: landscape) {
    padding: 0;

    & .fds-full-screen-image-gallery__overlay__close-button {
      padding: fds-rem(1px 0);
    }
  }
}

.fds-full-screen-image-gallery__overlay__close-button {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  font-size: fds-rem(12px);
  line-height: fds-rem(12px);
  background-color: var(--fds-color--white);
  padding: fds-rem(11px 26px);

  &::before {
    display: block;
    font-family: $fds-font--ford-icons !important;
    content: $fds-font--ford-icons__clear;
    color: var(--fds-color--primary);
    padding: fds-rem(10px);
  }

  &.fds-full-screen-image-gallery__overlay__close-button--focused::before {
    outline: 1px solid var(--fds-color--primary);
  }

  @media only screen
    and (max-width: map-get($fds-breakpoints-max, 'fds-sm'))
    and (orientation: portrait) {
    padding-right: fds-rem(16px);
  }
}

.fds-carousel-indicator__carousel-slide__copy {
  padding: fds-rem(30px 15px);
}

.fds-carousel-indicator__carousel-slide__title {
  @extend %fmc-type--heading5;

  padding-bottom: fds-rem(30px);
}

.fds-carousel-indicator__carousel-slide__body {
  @extend %fmc-type--body1;
}
