$fds-banner-notification__bottom-border--height: fds-rem(8px);

.fds-banner-notification__section,
.fds-banner-notification__section__type--persistent {
  position: relative;
  width: 100%;
}

.fds-banner-notification__section__type--dismissible {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.fds-banner-notification {
  width: 100%;
  overflow: hidden;
  box-shadow: $fds-elevation__box-shadow--layer2;
}

.fds-banner-notification__close-button--focused {
  outline: 1px solid var(--fds-color--primary) !important;
}

.fds-banner-notification__type--dismissible {
  z-index: 9999;
}

.fds-banner-notification__type--dismissible,
.fds-banner-notification__type--persistent {
  position: relative;
}

.fds-banner-notification--show {
  & .fds-banner-notification__wrapper {
    margin-top: 0;
  }

  & .fds-banner-notification__content,
  & .fds-banner-notification__close-button::before {
    animation-name: fds-banner-notification__icon-and-text;
  }

  &.fds-banner-notification__style--default {
    & .fds-banner-notification__wrapper {
      animation-name: fds-banner-notification__wrapper--show, fds-banner-notification__wrapper--information;
    }

    & .fds-banner-notification__content::before {
      animation-name: fds-banner-notification__icon-and-text;
    }
  }

  &.fds-banner-notification__style--success {
    & .fds-banner-notification__wrapper {
      animation-name: fds-banner-notification__wrapper--show, fds-banner-notification__wrapper--success;
    }

    & .fds-banner-notification__content::before {
      animation-name: fds-banner-notification__icon-and-text;
    }
  }

  &.fds-banner-notification__style--warning {
    & .fds-banner-notification__wrapper {
      animation-name: fds-banner-notification__wrapper--show, fds-banner-notification__wrapper--warning;
    }

    & .fds-banner-notification__content::before {
      animation-name: fds-banner-notification__icon-and-text;
    }
  }

  &.fds-banner-notification__style--error {
    & .fds-banner-notification__wrapper {
      animation-name: fds-banner-notification__wrapper--show, fds-banner-notification__wrapper--error;
    }

    & .fds-banner-notification__content::before {
      animation-name: fds-banner-notification__icon-and-text;
    }
  }
}

.fds-banner-notification--hide {
  & .fds-banner-notification__wrapper {
    animation-duration: 0.3s;
    animation-name: fds-banner-notification__wrapper--hide;
    margin-top: fds-rem(-9999px);
  }
}

.fds-banner-notification__style--default {
  & .fds-banner-notification__wrapper {
    background-image: linear-gradient(to bottom, var(--fds-color--white) 50%, var(--fds-color--primary) 50%);
  }

  & .fds-banner-notification__content::before {
    content: $fds-font--ford-icons__info-filled;
    color: var(--fds-color--primary);
  }
}

.fds-banner-notification__style--success {
  & .fds-banner-notification__wrapper {
    background-image: linear-gradient(to bottom, var(--fds-color--white) 50%, var(--fds-color--success1) 50%);
  }

  & .fds-banner-notification__content::before {
    content: $fds-font--ford-icons__check-filled;
    color: var(--fds-color--success1);
  }
}

.fds-banner-notification__style--warning {
  & .fds-banner-notification__wrapper {
    background-image: linear-gradient(to bottom, var(--fds-color--white) 50%, var(--fds-color--caution2) 50%);
  }

  & .fds-banner-notification__content::before {
    content: $fds-font--ford-icons__warning-filled;
    color: var(--fds-color--caution2);
  }
}

.fds-banner-notification__style--error {
  & .fds-banner-notification__wrapper {
    background-image: linear-gradient(to bottom, var(--fds-color--white) 50%, var(--fds-color--error1) 50%);
  }

  & .fds-banner-notification__content::before {
    content: $fds-font--ford-icons__warning-filled;
    color: var(--fds-color--error1);
  }
}

.fds-banner-notification__wrapper {
  padding: fds-rem(15px 30px 23px);
  margin-top: fds-rem(-9999px);
  width: 100%;
  background-size: 100% 200%;
  background-position: 0% calc(0% - #{$fds-banner-notification__bottom-border--height});
  animation-duration: 0.6s;
  animation-timing-function: linear;
}

a.fds-banner-notification__wrapper {
  display: block;

  & .fds-banner-notification__text::after {
    @extend %fds-icons;
    content: $fds-font--ford-icons__chevron-right;
    font-size: fds-rem(12px);
    padding-left: fds-rem(15px);
  }
}

.fds-banner-notification__content {
  padding-right: fds-rem(25px);
  animation-duration: 0.6s;
  animation-timing-function: linear;
  display: flex;
  align-items: top;

  &::before {
    @extend %fds-icons;
    font-size: fds-rem(22px);
    line-height: fds-rem(18px);
    padding-right: fds-rem(15px);
    animation-duration: 0.6s;
    animation-timing-function: linear;
  }
}

.fds-banner-notification__text {
  @extend %fmc-type--body1;
}

.fds-banner-notification__close-button {
  position: absolute;
  top: fds-rem(4px);
  right: fds-rem(18px);
  font-size: fds-rem(12px);
  line-height: fds-rem(18px);
  padding: fds-rem(12px);

  &::before {
    animation-duration: 0.6s;
    animation-timing-function: linear;
  }
}

@keyframes fds-banner-notification__wrapper--show {
  0% {
    margin-top: fds-rem(-58px);
    background-position: 0% 0%;
  }

  50% {
    margin-top: 0;
    background-position: 0% -100%;
  }

  100% {
    background-position: 0% calc(-200% - #{$fds-banner-notification__bottom-border--height});
  }
}

@keyframes fds-banner-notification__wrapper--hide {
  0% {
    margin-top: 0;
  }

  100% {
    margin-top: fds-rem(-58px);
  }
}

@keyframes fds-banner-notification__wrapper--information {
  0% {
    background-image: linear-gradient(to bottom, var(--fds-color--primary) 50%, var(--fds-color--primary) 50%);
  }
}

@keyframes fds-banner-notification__wrapper--success {
  0% {
    background-image: linear-gradient(to bottom, var(--fds-color--success1) 50%, var(--fds-color--success1) 50%);
  }
}

@keyframes fds-banner-notification__wrapper--warning {
  0% {
    background-image: linear-gradient(to bottom, var(--fds-color--caution2) 50%, var(--fds-color--caution2) 50%);
  }
}

@keyframes fds-banner-notification__wrapper--error {
  0% {
    background-image: linear-gradient(to bottom, var(--fds-color--error1) 50%, var(--fds-color--error1) 50%);
  }
}

@keyframes fds-banner-notification__icon-and-text {
  0% {
    opacity: 0;
    color: var(--fds-color--white);
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  75% {
    color: var(--fds-color--white);
  }
}
