.fds-toggle {
  position: relative;
  width: fds-rem(82px);
  height: fds-rem(40px);

  &:not(.fds-toggle--disabled) {
    cursor: pointer;
  }

  &.fds-toggle--interior {
    &.fds-toggle--disabled {
      & .fds-toggle__values {
        display: none;
      }
    }

    & .fds-toggle__values {
      color: var(--fds-color--white);
    }

    & .fds-toggle__checked-value {
      position: absolute;
      left: fds-rem(12px);
    }

    & .fds-toggle__unchecked-value {
      position: absolute;
      right: fds-rem(12px);
    }
  }

  &.fds-toggle--exterior {
    & .fds-toggle__values {
      color: var(--fds-color--gray3);
    }

    & .fds-toggle__checked-value {
      position: absolute;
      right: fds-rem(-36px);
    }

    & .fds-toggle__unchecked-value {
      position: absolute;
      left: fds-rem(-36px);
    }
  }

  &.fds-toggle--focused {
    & .fds-toggle__input-wrapper {
      &::before {
        outline: 1px solid var(--fds-color--primary);
        outline-offset: fds-rem(10px);
      }
    }
  }

  &.fds-toggle--disabled {
    & .fds-toggle__input {
      cursor: default;
    }

    & .fds-toggle__slider {
      background: var(--fds-color--disabled1);
    }
  }
}

/* stylelint-disable color-hex-length */

.fds-toggle__input-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: fds-rem(26.7px);
  box-shadow: 0 1px 0 -1px #fff, 0 fds-rem(-5px) fds-rem(10px) fds-rem(2px) #fff,
    0 fds-rem(-5px) fds-rem(10px) fds-rem(2px) #fff, 0 fds-rem(10px) fds-rem(10px) 0 rgba(0, 0, 0, 0.2),
    inset 0 fds-rem(4px) fds-rem(7px) 0 rgba(47, 47, 47, 0.35), inset 0 fds-rem(-2px) fds-rem(4px) 0 #ababab;
  background-color: var(--fds-color--gray2);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.fds-toggle__input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:disabled {
    opacity: 0;
  }

  &:checked {
    & + .fds-toggle__input-wrapper {
      background-color: var(--fds-color--secondary);

      & .fds-toggle__slider {
        margin-left: fds-rem(16px);
        transform: translateX(100%);
      }
    }
  }
}

.fds-toggle__slider {
  position: absolute;
  top: fds-rem(4.7px);
  margin-left: fds-rem(5px);
  width: fds-rem(30.7px);
  height: fds-rem(30.7px);
  border-radius: fds-rem(25.3px);
  box-shadow: 0 fds-rem(5px) fds-rem(5px) 0 rgba(0, 0, 0, 0.1), 0 fds-rem(5px) fds-rem(5px) 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to bottom, #fff, #f6f6f6);
  transform: translateX(0);
  transition: background-color 0.2s cubic-bezier(0, 0, 1, 1), transform 0.2s cubic-bezier(0, 0, 1, 1);
}

.fds-toggle__values {
  @include fds-get-component-typeset('toggle-value');

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--fds-color--white);
  display: flex;
  align-items: center;
}

.fds-toggle__label {
  @include fds-get-component-typeset('toggle-label');
  cursor: pointer;
}
