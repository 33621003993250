.fds-gallery {
  margin-bottom: fds-rem(4px);
  padding: 0;
}

.fds-gallery .fds-layout-grid__inner {
  grid-auto-flow: dense;
  grid-gap: fds-rem(4px);
}

.fds-gallery__media {
  grid-row: span 2;
  margin: 0;
  overflow: hidden;
  position: relative;
}

.fds-layout-grid__cell--span-4 {
  grid-row: span 1;
}

.fds-gallery__media:focus {
  outline: none;
}

.fds-gallery--focused::after {
  content: '';
  position: absolute;
  border: fds-rem(1px) solid var(--fds-color--secondary);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.fds-gallery__img {
  display: block;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  width: 100%;
}

.fds-gallery__caption {
  background-color: var(--fds-color--primary-90);
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  width: 100%;
}

.fds-gallery__title {
  @extend %fmc-type--heading5;
  color: var(--fds-color--white);
  padding: fds-rem(22px) fds-rem(30px);
}

.fds-gallery__media:hover .fds-gallery__img, .fds-gallery--focused .fds-gallery__img {
  transform: scale(1.07);
}

.fds-gallery__media:hover .fds-gallery__caption, .fds-gallery--focused .fds-gallery__caption {
  transform: translateY(0);
}

@include media('>=fds-md') {
  .fds-gallery--left .fds-layout-grid__cell--span-4-md, .fds-gallery--right .fds-layout-grid__cell--span-4-md {
    grid-row: span 1;
  }

  .fds-gallery--right .fds-layout-grid__cell--span-8 {
    grid-column-start: 5;
  }

  .fds-gallery--right .fds-layout-grid__cell--span-4-md + .fds-layout-grid__cell--span-4-md {
    grid-row-start: 2;
  }
}
