.fmc-filter-chips {
  --icon-gap: 0.8rem;
  --dot-size: 2.4rem;

  display: flex;
  gap: 2rem;
  flex-wrap: wrap;

  > * {
    display: flex;
  }

  .fmc-filter-chips__dot {
    display: inline-block;
    width: var(--dot-size);
    height: var(--dot-size);
    margin-right: var(--icon-gap);
    border-radius: 50%;
  }

  .fmc-filter-chips__chip {
    display: flex;
    align-items: center;
    color: var(--fds-color--primary);
    font-size: 2.4rem;
    font-stretch: condensed;
    height: 4rem;
    padding: 0 1.6rem;
    border-radius: 0.3rem;
    border: 0.1rem solid var(--fds-color--primary);

    &:hover:not(.fmc-filter-chips__chip--disabled) {
      border: 0.1rem solid var(--fds-color--secondary);
      background-color: var(--fds-color--primary-5);
    }

    &:active:not(.fmc-filter-chips__chip--disabled) {
      border: 0.1rem solid var(--fds-color--secondary);
      background-color: transparent;
    }
  }

  .fmc-filter-chips__chip--disabled {
    color: var(--fds-color--disabled3);
    border: 1px solid var(--fds-color--disabled3);

    & * {
      cursor: not-allowed;
    }
  }

  &.fmc-filter-chips--scrollable {
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: 1rem;
  }

  &.fmc-filter-chips--scrollable .fmc-filter-chips__chip {
    flex: 0 0 auto;
  }

  .fds-font--ford-icons__clear:focus-visible {
    outline: var(--fds-outline);
    outline-offset: 0.8rem;
  }
}
