%fds-divider {
  border: unset;
}

.fds-divider__vertical-full--fluid {
  border-bottom: 1px solid var(--fds-color--black-40);

  @include media('>=fds-sm') {
    border: none;
    border-right: 1px solid var(--fds-color--black-40);
  }
}

.fds-divider__vertical-middle--fluid {
  border-bottom: 1px solid var(--fds-color--black-20);

  @include media('>=fds-sm') {
    border: none;
    border-right: 1px solid var(--fds-color--black-20);
  }
}

.fds-divider__vertical-full--fixed {
  border-right: 1px solid var(--fds-color--black-40);
}

.fds-divider__vertical-middle--fixed {
  border-right: 1px solid var(--fds-color--black-20);
}

.fds-divider__horizontal-full {
  @extend %fds-divider;
  width: 100%;
  border-bottom: 1px solid var(--fds-color--black-40);
}

.fds-divider__horizontal-middle {
  @extend %fds-divider;
  width: 100%;
  border-bottom: 1px solid var(--fds-color--black-20);
}

.fds-divider__horizontal-full__parent {
  overflow: hidden;
}
