// ================================
// Utilities: Overflow
// ================================

$fds-overflow: (
  auto: auto,
  hidden: hidden,
  visible: visible,
  scroll: scroll
) !default;

@include fds-set-selectors($fds-overflow, 'overflow', 'fds-overflow--');
