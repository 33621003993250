.fmc-global-attribute-bar  {
  --border-color: var(--fds-color--black-40);

  box-shadow: $fds-elevation__box-shadow--layer1;

  &.fds-bg--dark {
    --border-color: var(--fds-color--white);
  }

  a {
    font-stretch: condensed;
    text-decoration: underline;
  }

  .fmc-global-attribute-bar__item:not(:last-child) {
    position: relative;
    border-bottom: 1px solid var(--border-color);
  }
}

// determine when to switch the horizontal rule to a vertical rule
.fmc-global-attribute-bar--vr-sm .fmc-global-attribute-bar__item:not(:last-child) {
  @include media('>=fds-sm') {
    border-bottom: none;

    &::before {
      content: " ";
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      right: -8px;
      background: var(--border-color);
    }
  }
}

.fmc-global-attribute-bar--vr-md .fmc-global-attribute-bar__item:not(:last-child) {
  @include media('>=fds-md') {
    border-bottom: none;

    &::before {
      content: " ";
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      right: -8px;
      background: var(--border-color);
    }
  }
}

.fmc-global-attribute-bar--vr-lg .fmc-global-attribute-bar__item:not(:last-child) {
  @include media('>=fds-lg') {
    border-bottom: none;

    &::before {
      content: " ";
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      right: -8px;
      background: var(--border-color);
    }
  }
}

.fmc-global-attribute-bar--vr-xl .fmc-global-attribute-bar__item:not(:last-child) {
  @include media('>=fds-xl') {
    border-bottom: none;

    &::before {
      content: " ";
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      right: -8px;
      background: var(--border-color);
    }
  }
}
