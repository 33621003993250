.fds-creditcard-field .fds-creditcard-field__input--typeknown {
  padding-left: fds-rem(52px);
  transition: padding-left 0.3s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: fds-rem(9px);
    left: fds-rem(10px);
    width: fds-rem(42px);
    height: fds-rem(30px);
    border: 1px solid var(--fds-color--gray2);
    border-radius: fds-rem(3px);
    background-repeat: no-repeat;
    background-position: center;
  }
}

.fds-creditcard-field__error-message {
  display: block;
}
