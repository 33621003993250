%flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fmc-pagination {
  @extend %flex-centered;

  --pagination-gap: #{fds-rem(8px)};
  --pagination-prev-next-padding-x: #{fds-rem(24px)};
  --pagination-item-padding-x: #{fds-rem(16px)};
  --pagination-item-padding-y: #{fds-rem(8px)};
  --pagination-item-width: #{fds-rem(40px)};
  --pagination-item-height: #{fds-rem(40px)};
  --pagination-outline-width: 1px;
  --pagination-active-border-width: 2px;

  list-style: none;
  margin: 0 auto;
  gap: var(--pagination-gap);
}

.fmc-pagination__item {
  display: flex;
  outline: var(--pagination-outline-width) solid transparent;
  transition: outline-color var(--fmc-transition-duration) linear;

  &:focus-within {
    outline-color: var(--fds-color--primary);
    color: var(--fds-color--secondary);
  }
}

.fmc-pagination__link {
  @extend %flex-centered;

  min-width: var(--pagination-item-width);
  height: var(--pagination-item-height);
  text-align: center;
  align-self: center;
  color: var(--fds-color--gray3);
}

.fmc-pagination__active {
  color: var(--fds-color--primary);
  border-bottom: var(--pagination-active-border-width) solid var(--fds-color--primary);
  transform: translateY(calc(var(--pagination-active-border-width) / 2));
}

.fmc-pagination__prev,
.fmc-pagination__next {
  @extend %flex-centered;

  --pagination-item-height: #{fds-rem(56px)};

  padding-left: var(--pagination-item-padding-x);
  padding-right: var(--pagination-item-padding-x);
  height: var(--pagination-item-height);
  color: var(--fds-color--primary);
  white-space: nowrap;

  &:focus-visible {
    outline: var(--pagination-outline-width) solid var(--fds-color--primary);
    color: var(--fds-color--secondary);
  }

  &::before,
  &::after {
    font-family: var(--fmc-font--ford-icons);
  }
}

.fmc-pagination__prev {
  padding-right: var(--pagination-prev-next-padding-x);

  &::before {
    content: var(--fds-font--ford-icons__chevron-left);
    padding-right: var(--pagination-item-padding-x);
  }
}

.fmc-pagination__next {
  padding-left: var(--pagination-prev-next-padding-x);

  &::after {
    content: var(--fds-font--ford-icons__chevron-right);
    padding-left: var(--pagination-item-padding-x);
  }
}

a, span, * { /* element required to override default element styling */
  &.fmc-pagination__disabled {
    color: var(--fds-color--disabled3);
    pointer-events: none;
  }
}
