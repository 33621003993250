// ================================
// Utilities: Show
// ================================

.fds-show {
  display: block !important;
}

.fds-show--xs {
  display: none;

  @include media('>=fds-xs', '<fds-sm') {
    display: block;
  }
}

.fds-show--sm {
  display: none;

  @include media('>=fds-sm', '<fds-md') {
    display: block;
  }
}

.fds-show--md {
  display: none;

  @include media('>=fds-md', '<fds-lg') {
    display: block;
  }
}

.fds-show--lg {
  display: none;

  @include media('>=fds-lg', '<fds-xl') {
    display: block;
  }
}

.fds-show--xl {
  display: none;

  @include media('>=fds-xl') {
    display: block;
  }
}

.fds-visible {
  visibility: visible !important;
}

