.fds-activity-indicator--linear:not(.fds--ie11) {
  width: 100%;
  flex-direction: column;
  justify-content: center;

  & .fds-activity-indicator__display-percent {
    text-align: center;
    margin-top: fds-rem(8px);
  }

  & .fds-activity-indicator__progress-wrapper {
    width: 100%;
    position: relative;
    height: fds-rem(3px);
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: fds-rem(1px);
      background-color: var(--fds-color--disabled3);
    }
  }

  & .fds-activity-indicator__progress-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }

  & .fds-activity-indicator__progress-line_svg--indeterminate {
    width: 100%;
    height: 100%;
  }

  & .fds-activity-indicator__progress-line_line--indeterminate {
    stroke-width: fds-rem(3px);
    stroke-linecap: round;
    stroke: var(--fds-color--primary);
  }

  & .fds-activity-indicator__progress-line_svg--determinate {
    width: 100%;
    height: 100%;
  }

  & .fds-activity-indicator__progress-line_line--determinate {
    stroke-width: fds-rem(3px);
    stroke-linecap: round;
    stroke: var(--fds-color--primary);
  }
}

.fds-activity-indicator--circular:not(.fds--ie11) {
  width: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;

  & .fds-activity-indicator__display-percent {
    position: absolute;
  }

  & .fds-activity-indicator__progress-wrapper {
    width: fds-rem(81px);
    height: fds-rem(81px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      display: block;
      border: fds-rem(1px) solid var(--fds-color--disabled3);
      border-radius: 50%;
      width: fds-rem(75px);
      height: fds-rem(75px);
    }
  }

  & .fds-activity-indicator__progress-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .fds-activity-indicator__progress-line_svg {
    width: fds-rem(81px);
    height: fds-rem(81px);
    transform: rotate(-90deg);
  }

  & .fds-activity-indicator__progress-line_line--indeterminate {
    stroke-dasharray: 38,162;
    stroke-dashoffset: 0;
    stroke-width: fds-rem(2px);
    animation: fds-activity-indicator__animation--circular--indeterminate 3s linear infinite;
    stroke-linecap: round;
    stroke: var(--fds-color--primary);
  }

  & .fds-activity-indicator__progress-line_line--determinate {
    stroke-dasharray: 145;
    stroke-width: fds-rem(2px);
    animation: fds-activity-indicator__animation--circular--determinate 3s linear 1;
    stroke-linecap: round;
    stroke: var(--fds-color--primary);
  }

  /* stylelint-disable at-rule-no-vendor-prefix */
  @-webkit-keyframes fds-activity-indicator__animation--circular--indeterminate {
    0% {
      stroke-dashoffset: 200;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes fds-activity-indicator__animation--circular--indeterminate {
    0% {
      stroke-dashoffset: 200;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes fds-activity-indicator__animation--circular--determinate {
    0% {
      stroke-dashoffset: 145;
    }
  }

  @keyframes fds-activity-indicator__animation--circular--determinate {
    0% {
      stroke-dashoffset: 145;
    }
  }
}

.fds-activity-indicator__display-percent {
  @include fds-get-component-typeset('activity-indicator');
}

.fds-activity-indicator__notification,
.fds-activity-indicator__instructions {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
