// ================================
// Utilities: Padding
// ================================

// TODO make these root variables
$fds-paddings: (
  0: 0,
  1: fds-rem(10px),
  2: fds-rem(20px),
  3: fds-rem(40px),
  4: fds-rem(80px)
) !default;

@each $label, $value in $fds-paddings {
  .fds-p--t-#{$label} {
    padding-top: $value;
  }
}

@each $label, $value in $fds-paddings {
  .fds-p--b-#{$label} {
    padding-bottom: $value;
  }
}

@each $label, $value in $fds-paddings {
  .fds-p--l-#{$label} {
    padding-left: $value;
  }
}

@each $label, $value in $fds-paddings {
  .fds-p--r-#{$label} {
    padding-right: $value;
  }
}

@each $label, $value in $fds-paddings {
  .fds-p--x-#{$label} {
    padding-left: $value;
    padding-right: $value;
  }
}

@each $label, $value in $fds-paddings {
  .fds-p--y-#{$label} {
    padding-top: $value;
    padding-bottom: $value;
  }
}

// --------------------------------
// Responsive Padding
// --------------------------------

@each $breakpoint in map-keys($fds-breakpoints) {
  @include media(#{'>=' + $breakpoint}) {
    @each $label, $value in $fds-paddings {
      .#{$breakpoint}\:fds-p--t-#{$label} {
        padding-top: $value;
      }
    }

    @each $label, $value in $fds-paddings {
      .#{$breakpoint}\:fds-p--b-#{$label} {
        padding-bottom: $value;
      }
    }

    @each $label, $value in $fds-paddings {
      .#{$breakpoint}\:fds-p--l-#{$label} {
        padding-left: $value;
      }
    }

    @each $label, $value in $fds-paddings {
      .#{$breakpoint}\:fds-p--r-#{$label} {
        padding-right: $value;
      }
    }

    @each $label, $value in $fds-paddings {
      .#{$breakpoint}\:fds-p--x-#{$label} {
        padding-left: $value;
        padding-right: $value;
      }
    }

    @each $label, $value in $fds-paddings {
      .#{$breakpoint}\:fds-p--y-#{$label} {
        padding-top: $value;
        padding-bottom: $value;
      }
    }
  }
}
