.fds-billboard {
  width: 100%;
  position: relative;
  display: flex;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.fds-billboard__content--top {
    align-items: flex-start;
    justify-content: center;

    & .fds-billboard__content {
      padding-top: fds-rem(30px);

      @include media('>=fds-sm') {
        padding-top: fds-rem(70px);
      }
    }
  }

  &.fds-billboard__content--bottom {
    align-items: flex-end;
    justify-content: center;

    & .fds-billboard__content {
      padding-bottom: fds-rem(30px);

      @include media('>=fds-sm') {
        padding-bottom: fds-rem(70px);
      }
    }
  }
}

/* stylelint-disable value-no-vendor-prefix */
.fds-billboard__content--top {
  &.fds-billboard--light {
    &::before {
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 50%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 50%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
    }

    &.fds-billboard--overlay::before {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  &.fds-billboard--dark {
    &::before {
      background: -moz-linear-gradient(top, var(--fds-color--tertiary-100) 0%, var(--fds-color--tertiary-0) 50%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, var(--fds-color--tertiary-100) 0%, var(--fds-color--tertiary-0) 50%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        var(--fds-color--tertiary-100) 0%,
        var(--fds-color--tertiary-0) 50%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    &.fds-billboard--overlay::before {
      background: var(--fds-color--tertiary-40);
    }
  }
}

.fds-billboard__content--bottom {
  &.fds-billboard--light {
    &::before, &.fds-billboard--gradient::before { // Legacy FDS didn't use fds-billboard-gradient class so defaulting to gradient if no gradient / overlay class is provided
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 1) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 1) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }

    &.fds-billboard--overlay::before {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  &.fds-billboard--dark {
    &::before {
      background: -moz-linear-gradient(top, var(--fds-color--tertiary-0) 50%, var(--fds-color--tertiary-100) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        var(--fds-color--tertiary-0) 50%,
        var(--fds-color--tertiary-100) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        var(--fds-color--tertiary-0) 50%,
        var(--fds-color--tertiary-100) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    &.fds-billboard--overlay::before {
      background: var(--fds-color--tertiary-40);
    }
  }
}

.fds-billboard--light, .fds-billboard--light-overlay {
  & .fds-billboard__content--title,
  & .fds-billboard__content--body {
    color: var(--fds-color--gray3);
  }
}

.fds-billboard--dark, .fds-billboard--dark-overlay {
  & .fds-billboard__content--title,
  & .fds-billboard__content--body {
    color: var(--fds-color--white);
  }
}

.fds-billboard__content {
  padding: fds-rem(0 30px);
  z-index: 3;
  width: 100%;

  & .fds-billboard__content--body {
    padding-top: fds-rem(20px);

    @include media('>=fds-sm') {
      padding-top: fds-rem(20px);
    }

    @include media('>=fds-md') {
      padding-top: fds-rem(30px);
    }
  }

  @include media('>=fds-sm') {
    width: 75%;
  }

  @include media('>=fds-md') {
    width: 80%;
  }

  @include media('>=fds-xl') {
    width: fds-rem(820px);
  }
}
