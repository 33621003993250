.fmc-table {
  --text-color: var(--fds-color--primary);
  --type-weight: var(--fmc-type__weight-normal);
  --background-color: var(--fds-color--white);
  --background-alternate-color: var(--fds-color--gray1);
  --border-width: #{fds-rem(1px)};
  --border-color: var(--fds-color--gray2); // NOTE: border color is not specified in invision
  --header-background-color: var(--fds-color--primary);
  --header-text-color: var(--fds-color--white);
  --header-border-color: var(--fds-color--white);
  --header-type-weight: var(--fmc-type__weight-bold);

  border-collapse: collapse;

  th,
  td {
    // Top padding is -1px to accommodate border
    padding: fds-rem(7px 16px 8px);
    border: var(--border-width) solid var(--border-color);
    background-color: var(--background-color);
    color: var(--text-color);
    font-weight: var(--type-weight);
    vertical-align: top;
  }

  %th-styles {
    --border-color: var(--header-border-color);
    --background-color: var(--header-background-color);
    --text-color: var(--header-text-color);
    --type-weight: var(--header-type-weight);
  }

  th {
    @extend %th-styles;
  }

  &.fmc-table--header-column {
    td:first-child {
      @extend %th-styles;
    }
  }

  &:not(.fmc-table--no-borders) {
    th {
      // When table body has borders, add border around the left-top-right of the header to avoid artifact
      border-top-color: var(--background-color);

      &:first-child {
        border-left-color: var(--background-color);
      }

      &:last-child {
        border-right-color: var(--background-color);
      }
    }

    &.fmc-table--header-column {
      // Similar for the header column
      th:first-child {
        border-bottom-color: var(--header-border-color);
      }

      td:first-child {
        border-left-color: var(--background-color);
      }

      tr:last-child td:first-child {
        border-bottom-color: var(--background-color);
      }
    }
  }

  &.fmc-table--no-borders {
    td {
      --border-color: var(--background-color);
    }
  }

  &.fmc-table--alternating-rows {
    tr:nth-child(odd) {
      --background-color: var(--background-alternate-color);
    }
  }
}
