.fds-color__bg--light {
  & .fds-tooltip {
    & .fds-tooltip__button__text-link-button {
      color: var(--fds-color--primary);
    }

    &.fds-tooltip--hover {
      & .fds-tooltip__button__text-link-button {
        color: var(--fds-color--secondary);
        border-bottom-color: var(--fds-color--secondary);
      }
    }

    &.fds-tooltip--focused {
      & .fds-tooltip__button {
        outline-color: var(--fds-color--primary);
      }
    }
  }
}

.fds-color__bg--dark {
  & .fds-tooltip {
    & .fds-tooltip__button__text-link-button {
      color: var(--fds-color--white);
    }

    &.fds-tooltip--hover {
      & .fds-tooltip__button__text-link-button {
        color: var(--fds-color--gray1);
        border-bottom-color: var(--fds-color--gray1);
      }
    }

    &.fds-tooltip--focused {
      & .fds-tooltip__button {
        outline-color: var(--fds-color--white);
      }
    }
  }
}

.fds-tooltip {
  position: relative;
  display: inline-block;

  &.fds-tooltip--hover {
    & .fds-tooltip__wrapper {
      opacity: 1;
    }

    &.fds-tooltip--left {
      & .fds-tooltip__wrapper {
        transform: translateX(8px);
      }
    }

    &.fds-tooltip--right {
      & .fds-tooltip__wrapper {
        transform: translateX(-8px);
      }
    }

    &.fds-tooltip--bottom {
      & .fds-tooltip__wrapper {
        transform: translateY(-8px);
      }
    }

    &.fds-tooltip--top {
      & .fds-tooltip__wrapper {
        transform: translateY(8px);
      }
    }
  }

  &.fds-tooltip--focused {
    & .fds-tooltip__button {
      outline-color: var(--fds-color--primary);
    }
  }

  & .fds-tooltip__button {
    display: inline-block;
    position: relative;
    z-index: 100;
    cursor: pointer;
    outline: 1px solid transparent;

    &.fds-tooltip__button__text-link-button {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      font-weight: normal; /* stylelint-disable-line font-weight-notation */
      outline-offset: fds-rem(10px);
      border-bottom-width: 1px;
      border-bottom-style: dashed;
    }

    &.fds-tooltip__button__icon-button {
      width: fds-rem(24px);
      height: fds-rem(24px);
      outline-offset: fds-rem(20px);

      & img {
        width: 100%;
      }
    }
  }

  & .fds-tooltip__wrapper {
    position: absolute;
    z-index: 115;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    top: fds-rem(-9999px);
    right: 0;
    width: auto;
    white-space: nowrap;
    height: fds-rem(50px);
    padding: fds-rem(15px);
    background-color: var(--fds-color--white);
    border: 1px solid var(--fds-color--gray3);
    border-radius: fds-rem(3px);
    box-shadow: $fds-elevation__box-shadow--layer2;

    & .fds-tooltip__pointer-fill,
    & .fds-tooltip__pointer-border {
      display: block;
      border-style: solid;
      content: "";
      height: fds-rem(20px);
      width: fds-rem(20px);
      position: absolute;
      pointer-events: none;
    }

    & .fds-tooltip__content-wrapper, & .fds-tooltip__content-wrapper p {
      @extend %fmc-type--body1;

      display: flex;
      flex-direction: column;
      overflow: hidden;
      color: var(--fds-color--gray3);
    }

    & .fds-tooltip__content {
      width: auto;
      max-width: fds-rem(270px);
      position: relative;
      display: block;
    }

    &.fds-tooltip--hide {
      top: fds-rem(-9999px) !important;
    }
  }

  &.fds-tooltip--left {
    & .fds-tooltip__wrapper {
      & .fds-tooltip__pointer-fill {
        border-color: transparent transparent transparent var(--fds-color--white);
        margin-top: fds-rem(-11px);
        border-width: fds-rem(11px);
      }

      & .fds-tooltip__pointer-border {
        border-color: transparent transparent transparent var(--fds-color--gray3);
        margin-top: fds-rem(-12px);
        border-width: fds-rem(12px);
      }
    }
  }

  &.fds-tooltip--right {
    & .fds-tooltip__wrapper {
      & .fds-tooltip__pointer-fill {
        border-color: transparent var(--fds-color--white) transparent transparent;
        margin-top: fds-rem(-10px);
        border-width: fds-rem(10px);
      }

      & .fds-tooltip__pointer-border {
        border-color: transparent var(--fds-color--gray3) transparent transparent;
        margin-top: fds-rem(-11px);
        border-width: fds-rem(11px);
      }
    }
  }

  &.fds-tooltip--bottom {
    & .fds-tooltip__wrapper {
      & .fds-tooltip__pointer-fill {
        border-color: transparent transparent var(--fds-color--white) transparent;
        margin-top: fds-rem(-11px);
        border-width: fds-rem(11px);
      }

      & .fds-tooltip__pointer-border {
        border-color: transparent transparent var(--fds-color--gray3) transparent;
        margin-top: fds-rem(-12px);
        border-width: fds-rem(11px);
      }
    }
  }

  &.fds-tooltip--top {
    & .fds-tooltip__wrapper {
      & .fds-tooltip__pointer-fill {
        border-color: var(--fds-color--white) transparent transparent transparent;
        margin-top: fds-rem(-12px);
        border-width: fds-rem(11px);
      }

      & .fds-tooltip__pointer-border {
        border-color: var(--fds-color--gray3) transparent transparent transparent;
        margin-top: fds-rem(-11px);
        border-width: fds-rem(11px);
      }
    }
  }
}
