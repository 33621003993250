.fds-overlay-mask {
  position: fixed;
  top: 0;
  right: fds-rem(9999px);
  width: 100%;
  height: 100%;
  z-index: 110;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-color: var(--fds-color--tertiary-70);

  &.fds-overlay-mask--show {
    opacity: 1;
    right: 0;
  }
}
