.fds-breadcrumbs {
  display: flex;
  list-style: none;
  margin: 1.5rem auto;
  align-items: center;
  flex-wrap: wrap;
}

.fds-breadcrumbs__current {
  color: var(--fds-color--gray2);
  pointer-events: none;
}

.fds-breadcrumbs__link,
.fds-breadcrumbs__current {
  display: flex;
  align-items: center;
  height: 4rem;

}

.fds-breadcrumbs__link {
  margin-right: 0.8rem;
}

.fds-breadcrumbs__link::after {
  content: $fds-font--ford-icons__chevron-right;
  font-family: 'FordIcons' !important;
  font-size: 1rem;
  margin-left: 0.8rem;
  color: var(--fds-color--gray2);
}

.fds-breadcrumbs__text:hover {
  text-decoration: underline;
}

.fds-breadcrumbs__link:focus-visible {
  outline: var(--fds-color--primary) solid fds-rem(1px);
  color: var(--fds-color--secondary);
}
