.fds-checkbox {
  display: flex;
  position: relative;
  height: fds-rem(17px);
  margin-bottom: fds-rem(20px);

  &.fds-checkbox--focused {
    & .fds-checkbox__box {
      outline: 1px solid var(--fds-color--primary);
      outline-offset: fds-rem(5px);
    }
  }

  &:not(.fds-checkbox--disabled) {
    & .fds-checkbox__label-wrapper,
    & .fds-checkbox__label-wrapper .fds-checkbox__label {
      cursor: pointer;
    }
  }

  & .fds-checkbox__input {
    opacity: 0;
    width: fds-rem(16px);
    height: fds-rem(16px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;

    &:checked + .fds-checkbox__label-wrapper {
      & .fds-checkbox__box,
      & .fds-checkbox__check {
        background-color: var(--fds-color--white);
        border-color: var(--fds-color--primary);
      }

      & .fds-checkbox__check {
        display: flex;
      }
    }
  }

  &.fds-checkbox--disabled {
    & .fds-checkbox__input {
      cursor: not-allowed;
    }

    & .fds-checkbox__label-wrapper {
      & .fds-checkbox__box,
      & .fmc-type--checkbox-label {
        cursor: not-allowed;
        background-color: var(--fds-color--disabled3);
        color: var(--fds-color--white);
      }
    }
  }

  & .fds-checkbox__label-wrapper {
    position: absolute;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
  }

  & .fds-checkbox__box {
    display: flex;
    align-items: center;
    width: fds-rem(16px);
    height: fds-rem(16px);
    border-style: solid;
    border-width: fds-rem(2px);
    border-radius: fds-rem(2px);
    background-color: transparent;
  }

  & .fds-checkbox__check {
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &::before {
      color: var(--fds-color--primary);
      font-size: fds-rem(10px);
      line-height: fds-rem(10px);
    }
  }

  & .fds-checkbox__box,
  & .fds-checkbox__check {
    border-color: var(--fds-color--gray2);
  }

  & .fds-checkbox__label {
    display: flex;
    align-items: center;
  }

  & .fmc-type--checkbox-label {
    @include fds-get-component-typeset('checkbox-label');

    margin-left: fds-rem(15px);
    padding: fds-rem(2px);
    color: var(--fds-color--gray3);
  }
}
