// ================================
// Base: Default
// ================================

// Base font size
$fds-base-font-size-percentage: 62.5% !default;

// Base unit multiplier for em conversion
$fds-base-multiplier-em: 16px !default;

// Base unit multiplier for rem conversion
$fds-base-multiplier-rem: 10px !default;

// Base asset path
$fds-assets: '/assets' !default;

// Font and icon paths
$fds-ford-font-directory: '#{$fds-assets}/fonts/ford' !default;
$fds-ford-icon-directory: '#{$fds-assets}/icons' !default;
$fds-lincoln-font-directory: '#{$fds-assets}/fonts/lincoln' !default;
