// ================================
// Tools: Get rgb
// ================================

/**
 * Convert a color into an RGB tuple.
 *
 * @param {string} $color - Color value.  Can be any color that Sass can interpret.
 *
 * This tuple is useful e.g. in the CSS function `rgba()` (not to be confused
 * by the Sass function of the same name.)  It enables you to do things like,
 *
 * @example scss - Define a transparency
 *  --color--tertiary: #{fds-color2rgb(#00142e)};                     // -> 0, 20, 46
 *  --overlay-color-tuple: var(--color--tertiary);
 *  --overlay-transparency: rgba(var(--overlay-color-tuple), 0.5);    // -> rgba(0, 20, 46, 0.5)
 *
 *  while keeping it easy to override `--overlay-color`.
 */

@function fds-color2rgb($color) {
  @return red($color), green($color), blue($color);
}
