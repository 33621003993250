.fds-section-search {
  display: flex;

  @media (min-width: map-get($fds-breakpoints, 'fds-sm')) {
    flex-direction: row;
  }

  &.fds-search--focused,
  &.fds-search--active,
  &.fds-search--dirty {
    & .fds-search__input-wrapper::before {
      color: var(--fds-color--primary);
    }
  }

  &.fds-search--dirty {
    & .fds-section-search__clear-button {
      opacity: 1;
    }
  }

  & .fds-search__input-wrapper::before {
    @extend %fds-icons;

    content: $fds-font--ford-icons__search;
    margin-left: fds-rem(15px);
    width: fds-rem(16px);
    height: fds-rem(16px);
  }

  & .fds-search__input {
    padding-left: fds-rem(20px);
  }

  &.fds-color__bg--dark:not(.fds-search--focused):not(.fds-search--active):not(.fds-search--dirty) {
    & .fds-search__input-wrapper::before {
      color: var(--fds-color--white);
    }

    & .fds-section-search__clear-button::before {
      color: var(--fds-color--white);
    }
  }
}

.fds-section-search__combobox-wrapper {
  @media (min-width: map-get($fds-breakpoints, 'fds-sm')) {
    padding-right: fds-rem(20px);
  }
}

.fds-section-search__clear-button {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  margin-right: fds-rem(15px);
  color: var(--fds-color--primary);
}

.fds-section-search__clear-button--focused::before {
  outline: 1px solid var(--fds-color--primary);
  outline-offset: fds-rem(10px);
}

.fds-section-search__submit-button-wrapper {
  position: relative;
  top: fds-rem(31px);
  z-index: 2;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  & .fds-primary-button {
    @media (min-width: map-get($fds-breakpoints, 'fds-sm')) {
      position: relative;
      top: fds-rem(5px);
    }
  }
}
