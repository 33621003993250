.fds-tabs {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  margin: 0 auto;
  flex-direction: column;

  &.fds-tabs--vertical {
    &:not(.fds-tabs--horizontal) {
      flex-direction: row;

      & .fds-tabs__tablist-wrapper {
        position: absolute;
        transform-origin: left;
        z-index: 2;
      }

      & .fds-tabs__tabpanel {
        padding-right: fds-rem(120px);
      }
    }
  }
}

.fds-tabs__tablist-wrapper {
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  transform: none;
}

.fds-tabs__tablist {
  display: flex;
  position: relative;
  margin: 0 auto;
  width: fds-rem(1000px);
  transition: transform 0.3s linear;

  @include media('>=fds-md') {
    justify-content: flex-start;
  }

  & .fds-tabs__tab-border {
    display: block;
    position: absolute;
    height: fds-rem(3px);
    top: fds-rem(71px);
    left: 0;
    border-radius: fds-rem(3px);
    transition: transform 0.3s linear;
    background-color: var(--fds-color--primary);
  }
}

.fds-tabs__tablist-tab {
  padding: fds-rem(37px 0 46px);
  overflow: visible;
  position: relative;
  cursor: pointer;

  // remove flash on iOS mobile as accessibility indicators are customized in this CSS
  -webkit-tap-highlight-color: var(--fds-color--tertiary-0);

  & > * {
    pointer-events: none;
  }

  &::after {
    content: '';
    display: flex;
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background-color: transparent;
    transition: background-color 0.3s linear;
  }

  &.fds-tabs__tab--focused {
    & .fds-tabs__tab-text::before {
      content: '';
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      outline: 1px solid var(--fds-color--primary);
      outline-offset: fds-rem(20px);
    }
  }

  &.fds-tabs__tab--hover:not([aria-selected='true']) span {
    color: var(--fds-color--secondary)
  }

  &.fds-tabs__tab--hover:not([aria-selected='true'])::after {
    background-color: var(--fds-color--secondary);
  }
}

.fds-tabs__tab-text {
  @include fds-get-component-typeset('tabs');

  color: var(--fds-color--primary);
  padding: fds-rem(0 28px 20px 30px);
  width: 100%;
  text-align: center;
  display: block;
  white-space: nowrap;
  position: relative;
}

.fds-tabs__tabpanels {
  position: relative;
  display: flex;
  transition: transform 0.3s linear;
  margin: 1px;
}

.fds-tabs__tabpanel {
  position: relative;
  padding-right: 0;
  visibility: hidden;

  &.fds-tabs__tabpanel--focus {
    outline: 1px solid var(--fds-color--primary);
  }

  &.fds-tabs__tabpanel--active {
    visibility: visible
  }
}

.fds-tabs__tabpanel-content {
  padding: fds-rem(20px);
}
