// ================================
// Utilities: Alignments
// ================================

$fds-vertical-alignments: (
  baseline: baseline,
  top: top,
  middle: middle,
  bottom: bottom
) !default;

@include fds-set-selectors($fds-vertical-alignments, 'vertical-align', 'fds-va--');
