.fds-primary-button {
  position: relative;

  // Disabled
  &.fds-primary-button--disabled {
    & .fds-primary-button__button {
      cursor: not-allowed;
    }
  }

  // Hover
  &.fds-primary-button--hover {
    &:not(.fds-primary-button--touch):not(.fds-card-primary-button):not(.fds-primary-button--disabled) {
      & .fds-primary-button__button::before {
        transform: scale(1.07);
      }

      &:not(.fds-primary-button--outlined) {
        & .fds-primary-button__button::before {
          box-shadow: $fds-elevation__box-shadow--layer3;
        }
      }
    }
  }

  // Active
  &.fds-primary-button--active {
    & .fds-primary-button__button::before {
      transform: scale(1);
    }
  }

  // Focus
  &.fds-primary-button--focused {
    & .fds-primary-button__button::before {
      outline-width: fds-rem(1px);
      outline-style: solid;
      outline-offset: fds-rem(20px);
    }
  }

  &.fds-color__bg--dark {
    &.fds-primary-button--focused {
      &.fds-primary-button__button::before {
        outline-color: var(--fds-color--white);
      }
    }

    & .fds-primary-button__button {
      &::before {
        border-color: var(--fds-color--white);
      }
    }

    &:not(.fds-primary-button--outlined):not(.fds-primary-button--disabled) {
      & .fds-primary-button__button {
        &::before {
          background-color: var(--fds-color--white);
        }

        & .fds-primary-button__button-text {
          color: var(--fds-color--primary);
        }
      }

      &.fds-primary-button--hover,
      &.fds-primary-button--active {
        & .fds-primary-button__button::before {
          background-color: var(--fds-color--gray1);
          border-color: var(--fds-color--gray1);
        }
      }
    }

    &.fds-primary-button--outlined:not(.fds-primary-button--disabled) {
      &.fds-primary-button--hover {
        & .fds-primary-button__button::before {
          background-color: var(--fds-color--white-50);
        }
      }

      &.fds-primary-button--active {
        & .fds-primary-button__button::before {
          background-color: transparent;
        }
      }
    }

    &.fds-primary-button--disabled:not(.fds-primary-button--outlined) {
      & .fds-primary-button__button::before {
        background-color: var(--fds-color--disabled1);
        border-color: var(--fds-color--disabled1);
      }

      & .fds-primary-button__button-text {
        color: var(--fds-color--tertiary);
      }
    }

    &.fds-primary-button--disabled.fds-primary-button--outlined {
      & .fds-primary-button__button {
        &::before {
          background-color: transparent;
          border-color: var(--fds-color--disabled2);
        }

        & .fds-primary-button__button-text {
          color: var(--fds-color--disabled2);
        }
      }
    }

    &.fds-primary-button--outlined {
      & .fds-primary-button__button {
        & .fds-primary-button__button-text {
          color: var(--fds-color--white);
        }
      }
    }
  }

  &.fds-color__bg--light {
    &.fds-primary-button--focused {
      &.fds-primary-button__button::before {
        outline-color: var(--fds-color--primary);
      }
    }

    & .fds-primary-button__button::before {
      border-color: var(--fds-color--primary);
    }

    &:not(.fds-primary-button--outlined):not(.fds-primary-button--disabled) {
      & .fds-primary-button__button {
        &::before {
          background-color: var(--fds-color--primary);
        }

        & .fds-primary-button__button-text {
          color: var(--fds-color--white);
        }
      }

      &.fds-primary-button--hover,
      &.fds-primary-button--active {
        & .fds-primary-button__button::before {
          background-color: var(--fds-color--secondary);
          border-color: var(--fds-color--secondary);
        }
      }
    }

    &.fds-primary-button--outlined:not(.fds-primary-button--disabled) {
      &.fds-primary-button--hover {
        & .fds-primary-button__button::before {
          background-color: var(--fds-color--primary-5);
        }
      }

      &.fds-primary-button--active {
        & .fds-primary-button__button::before {
          background-color: transparent;
        }
      }
    }

    &.fds-primary-button--disabled:not(.fds-primary-button--outlined) {
      & .fds-primary-button__button::before {
        background-color: var(--fds-color--disabled3);
        border-color: var(--fds-color--disabled3);
      }

      & .fds-primary-button__button-text {
        color: var(--fds-color--white);
      }
    }

    &.fds-primary-button--disabled.fds-primary-button--outlined {
      & .fds-primary-button__button {
        &::before {
          background-color: transparent;
          border-color: var(--fds-color--disabled3);
        }

        & .fds-primary-button__button-text {
          color: var(--fds-color--disabled3);
        }
      }
    }
  }

  &:not(.fds-card-primary-button):not(.fds-primary-button--outlined):not(.fds-primary-button--disabled) {
    & .fds-primary-button__button::before {
      box-shadow: $fds-elevation__box-shadow--layer2;
    }
  }

  & .fds-primary-button__button {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0;
    position: relative;

    // remove flash on iOS mobile as accessibility indicators are customized in this CSS
    -webkit-tap-highlight-color: var(--fds-color--tertiary-0);

    // prevent button elements from shifting in IE11
    top: 0;
    left: 0;

    &::before {
      content: '';
      border-radius: fds-rem(20px);
      border: 1px solid transparent;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      will-change: transform, background-color, border-color;
      transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
        border-color 0.3s ease-in-out;
    }

    & .fds-primary-button__button-text {
      @include fds-get-component-typeset('primary-button');

      position: relative;
      font-weight: 400;
      text-transform: capitalize;
      text-align: center;
      padding: fds-rem(12px 30px 11px);
      width: 100%;
      pointer-events: none; // Fix for IE11 :active not firing on link click

      &::after,
      &::before {
        font-family: 'FordIcons' !important;
        line-height: 1;
        font-size: fds-rem(16px);
        content: '';
        display: none;
      }

      &::after {
        padding-left: fds-rem(15px);
      }

      &::before {
        padding-right: fds-rem(15px);
      }

      &.fds-primary-button__button-icon--chevron-left::before,
      &.fds-primary-button__button-icon--chevron-right::after,
      &.fds-primary-button__button-icon--plus::after,
      &.fds-primary-button__button-icon--minus::after {
        display: inline;
      }

      &.fds-primary-button__button-icon--chevron-left::before {
        content: $fds-font--ford-icons__chevron-left;
      }

      &.fds-primary-button__button-icon--chevron-right::after {
        content: $fds-font--ford-icons__chevron-right;
      }

      &.fds-primary-button__button-icon--plus::after {
        content: $fds-font--ford-icons__plus;
      }

      &.fds-primary-button__button-icon--minus::after {
        content: $fds-font--ford-icons__minus;
      }
    }
  }
}


// FMC Button

.fmc-button {
  --color-disabled: var(--fds-color--disabled3);
  --color-disabled-dark: var(--fds-color--disabled1);
  --color-disabled-dark-secondary: var(--fds-color--disabled2);
  --bg-color: var(--fds-color--primary);
  --bg-color-dark: var(--fds-color--white);
  --bg-color-hover: var(--fds-color--secondary);
  --bg-color-hover-dark: var(--fds-color--white);
  --bg-color-active: var(--fds-color--primary);
  --bg-color-active-secondary: var(--fds-color--primary);
  --bg-color-secondary: unset;
  --bg-color-secondary-hover: var(--fds-color--primary-5);
  --bg-color-secondary-dark: unset;
  --bg-color-secondary-dark-hover: var(--fds-color--white-10);
  --border-width: 0.1rem;
  --border-color: transparent;
  --border-color-secondary: var(--fds-color--primary);
  --border-color-secondary-dark: var(--fds-color--white);
  --border-accent: transparent;
  --border-accent-hover: transparent;
  --height: 4rem;
  --padding: 0 3.2rem;
  --radius: 2rem;
  --shadow: #{$fds-elevation__box-shadow--layer2};
  --shadow-hover: #{$fds-elevation__box-shadow--layer3};
  --scale: 1;
  --scale-hover: 1.07;
  --text-color: var(--fds-color--white);
  --text-color-disabled: var(--fds-color--white);
  --text-color-disabled-dark: var(--fds-color--black);
  --text-color-hover: var(--text-color);
  --text-color-dark: var(--fds-color--primary);
  --text-color-secondary: var(--fds-color--primary);
  --text-color-secondary-hover: var(--fds-color--primary);
  --text-color-secondary-dark: var(--fds-color--white);
  --text-color-secondary-dark-hover: var(--fds-color--white);
  --text-line-height: #{$fds-line-height__primary-button--sm};
  --text-spacing: #{$fds-letter-spacing__primary-button--sm};
  --text-size: #{$fds-font-size__primary-button--sm};
  --icon-gap: 1.6rem;
  --icon-offset: 0;
  --icon-size: 0.9em;
  --icon-weight: normal;
  --cursor: pointer;
  --outline: none;

  @include fds-set-brand(lincoln) {
    --color-disabled: var(--fds-color--gray4);
    --color-disabled-dark: var(--fds-color--gray4);
    --bg-color-hover: var(--fds-color--primary);
    --bg-color-dark-hover: var(--fds-color--white);
    --bg-color-secondary-hover: var(--fds-color--primary);
    --bg-color-secondary-dark: unset;
    --bg-color-secondary-dark-hover: var(--fds-color--white);
    --bg-color-active: #0d0e15;
    --bg-color-active-secondary: var(--fds-color--gray4);
    --border-accent-hover: var(--fds-color--secondary);
    --border-width: 0.2rem;
    --height: 5rem;
    --padding: 0 2rem;
    --radius: unset;
    --scale: 1;
    --scale-hover: 1;
    --shadow: none;
    --shadow-hover: none;
    --text-color-secondary-hover: var(--fds-color--white);
    --text-color-secondary-dark-hover: var(--fds-color--primary);
    --text-size: #{fds-rem(16px)};
    --icon-gap: 0.8rem;
    --icon-offset: -0.5rem;
    --icon-size: 0.75em;
    --icon-weight: bold;
  }

  font-size: var(--text-size);
  font-stretch: condensed;
  line-height: var(--text-line-height);
  letter-spacing: var(--text-spacing);
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  background: none;
  border: none;
  padding: var(--padding);
  height: var(--height);
  position: relative;
  color: var(--text-color);
  z-index: 1;
  outline: var(--outline);
  outline-offset: var(--fds-outline-offset);
  cursor: var(--cursor);


  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(var(--scale));
    will-change: transform, background-color, border-color;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
      border-color 0.3s ease-in-out;
  }

  // accent
  &::before {
    display: none;
    border-bottom: 2px solid var(--border-accent);

    @include fds-set-brand(lincoln) {
      display: block;
    }
  }

  // border
  &::after {
    border-radius: var(--radius);
    border: var(--border-width) solid var(--border-color);
    background-color: var(--bg-color);
    z-index: -1;
  }

  &:not(.fmc-button--no-shadow)::after {
    box-shadow: var(--shadow);
  }

  // set focus styles for all browsers
  &:focus {
    --scale: var(--scale-hover);
    --shadow: var(--shadow-hover);
    --border-accent: var(--border-accent-hover);
    --bg-color: var(--bg-color-hover);
    --outline: var(--fds-outline);
    outline: var(--outline); // necessary specificity to override global :focus
  }

  // reset focus styles for browsers that support focus-visible
  &:focus:not(:focus-visible) {
    --scale: unset;
    --shadow: var(--shadow-hover);
    --bg-color: var(--fds-color--primary);
    --border-accent: transparent;
    --outline: none;

    &:hover {
      --scale: var(--scale-hover);
      --border-accent: var(--border-accent-hover);
    }
  }

  // set new focus-visible styles
  &:focus-visible {
    --outline: var(--fds-outline);
    outline: var(--outline); // necessary specificity to override global :focus
  }

  // hover states
  @include fds-set-hover {
    --scale: var(--scale-hover);
    --shadow: var(--shadow-hover);
    --border-accent: var(--border-accent-hover);

    color: var(--text-color-hover);

    &::after {
      --bg-color: var(--bg-color-hover);
    }
  }

  // active states
  &:active {
    --bg-color: var(--bg-color-active);
  }

  // disabled states
  @include fds-set-disabled {
    --bg-color: var(--color-disabled);
    --bg-color-hover: var(--color-disabled);
    --border-accent: transparent;
    --shadow: none;
    --scale: var(--scale);
    --cursor: not-allowed;
  }

  &:link {
    color: var(--text-color);
  }

  .fds-icon {
    font-size: var(--icon-size);

    &::before {
      color: inherit;
    }
  }

  // base dark styles
  @include fds-set-dark {
    --text-color: var(--text-color-dark);
    --bg-color: var(--bg-color-dark);
    --bg-color-hover: var(--bg-color-hover-dark);

    // disabled state for base dark
    @include fds-set-disabled {
      --text-color: var(--text-color-disabled-dark);
      --bg-color: var(--color-disabled-dark);
      --bg-color-hover: var(--color-disabled-dark);
    }

    &:focus {
      --bg-color: var(--bg-color-dark);
      --outline: var(--fds-outline-dark);
    }

    &:focus:not(:focus-visible) {
      --outline: none;
    }

    &:focus-visible {
      --outline: var(--fds-outline-dark);
    }
  }

  // outlined
  &.fmc-button--outlined {
    --text-color: var(--text-color-secondary);
    --text-color-hover: var(--text-color-secondary-hover);
    --bg-color: var(--bg-color-secondary);
    --bg-color-hover: var(--bg-color-secondary-hover);
    --border-color: var(--border-color-secondary);
    --shadow: none;
    --shadow-hover: none;

    &:focus:not(:focus-visible) {
      --bg-color: var(--bg-color-secondary);

    }

    // dark styles for outlined
    @include fds-set-dark {
      --bg-color: var(--bg-color-secondary-dark);
      --bg-color-hover: var(--bg-color-secondary-dark-hover);
      --border-color: var(--border-color-secondary-dark);
      --text-color: var(--text-color-secondary-dark);
      --text-color-hover: var(--text-color-secondary-dark-hover);
    }

    // disable dstate for outlined
    @include fds-set-disabled {
      --bg-color: unset;
      --bg-color-hover: unset;
      --text-color: var(--color-disabled);
      --text-color-hover: var(--color-disabled);
      --border-color: var(--color-disabled);

      // dark styles with disabled state
      @include fds-set-dark {
        --text-color: var(--color-disabled-dark-secondary);
        --text-color-hover: var(--color-disabled-dark-secondary);
        --border-color: var(--color-disabled-dark-secondary);
      }
    }
  }

  [class*="fds-font--ford-icons__chevron"] {
    font-weight: var(--icon-weight);
  }
}
