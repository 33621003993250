// ================================
// Elevation
// ================================

// Box Shadow: offset-x | offset-y | blur-radius | spread-radius | color
// Do not reformat these values. The value must be on the same line as the property for the Code Kit guidelines.

/* example: Primary Button Active */
$fds-elevation__box-shadow--layer1: 0 fds-rem(10px) fds-rem(10px) 0 rgba(0, 0, 0, 0.1) !default;

/* example: Primary Button Default */
$fds-elevation__box-shadow--layer2: 0 fds-rem(10px) fds-rem(10px) 0 rgba(0, 0, 0, 0.1), 0 fds-rem(20px) fds-rem(20px) 0 rgba(0, 0, 0, 0.1), 0 fds-rem(30px) fds-rem(30px) 0 rgba(0, 0, 0, 0.15) !default;

/* example: Primary Button Hover */
$fds-elevation__box-shadow--layer3: 0 fds-rem(11px) fds-rem(11px) 0 rgba(0, 0, 0, 0.1), 0 fds-rem(21px) fds-rem(21px) 0 rgba(0, 0, 0, 0.1), 0 fds-rem(32px) fds-rem(32px) 0 rgba(0, 0, 0, 0.15) !default;
