.fmc-bottom-banner {
  --background-color: var(--fds-color--tertiary);
  --text-color: var(--fds-color--white);
  --border-color: var(--fds-color--fourth);
  --border-thickness: #{fds-rem(1px)};
  --border: var(--border-thickness) solid var(--border-color);
  --shadow: var(--fmc-elevation__box-shadow--layer3);
  --padding: #{fds-rem(16px 16px 24px)};
  --padding--desktop: #{fds-rem(16px 32px 24px)};
  --grid-template: "content dismiss" "cta cta" / 1fr auto;
  --grid-template--desktop: "content cta dismiss" / 1fr auto auto;
  --margin__button: #{fds-rem(24px 0 0)};
  --margin__button--desktop: #{fds-rem(0 0 0 16px)};
  --margin__dismiss: #{fds-rem(0 0 0 16px)};
  --outline-offset: #{fds-rem(6px)};
  --dismiss-icon-size: #{fds-rem(24px)};

  @media (min-width: map-get($fds-breakpoints, 'fds-md')) {
    --padding: var(--padding--desktop);
    --grid-template: var(--grid-template--desktop);
    --margin__button: var(--margin__button--desktop);
  }

  @include fds-set-brand(lincoln) {
    --background-color: var(--fds-color--primary);
    --text-color: var(--fds-color--tertiary);
  }

  background-color: var(--background-color);
  color: var(--text-color);
  border: var(--border);
  box-shadow: var(--shadow);
  padding: var(--padding);
  line-height: var(--leading);
  transition: var(--fmc-transition-duration) ease-out;
  overflow: hidden;

  width: 100%;

  &.fmc-bottom-banner--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
  }

  &[hidden] {
    opacity: 0;
    max-height: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  display: grid;
  grid-template: var(--grid-template);

  .fmc-bottom-banner__content {
    grid-area: content;
    align-self: center;
  }

  .fmc-bottom-banner__cta {
    grid-area: cta;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    gap: fds-rem(16px);
    margin: var(--margin__button);
  }

  .fmc-bottom-banner__dismiss {
    grid-area: dismiss;
    align-self: flex-start;
    justify-self: end;
    margin: var(--margin__dismiss);

    --icon-size: var(--dismiss-icon-size);

    &:focus-visible {
      outline: var(--fds-outline-dark);
      outline-offset: var(--outline-offset);
    }
  }
}
