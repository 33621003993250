fmc-accordion {
  &[enhanced] {
    --fmc-accordion-panel-height: auto;

    [slot="trigger"] {
      width: 100%;
    }

    .content {
      display: grid;
      grid:
        ".    label       feedback"
        "icon title       feedback"
        ".    descriptor  feedback"
        / auto 1fr auto;

      font-size: 1.1rem;
      text-align: left;
      align-items: center;
      column-gap: 1.8rem;
      margin-right: 1.8rem;
      padding: 0.8rem 0;

      > fmc-icon {
        grid-area: icon;
      }

      .label {
        display: inline-flex;
        grid-area: label;
        margin-bottom: 0.8rem;
      }

      .title {
        grid-area: title;
        font-size: 1.6rem;
        line-height: 2.4rem;
        letter-spacing: 1px;
      }

      .descriptor {
        grid-area: descriptor;
        margin-top: 0.8rem;
      }

      .feedback {
        grid-area: feedback;
        grid-row: span 3;
      }
    }
  }
}