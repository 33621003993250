// ================================
// Flex Columns
// ================================

$fds-flex__row-gutters: (
  gapless: fds-rem(0),
  default: fds-rem(-$fds-flex__gutter),
  narrow: fds-rem(-$fds-flex__gutter/2),
  wide: fds-rem(-$fds-flex__gutter * 2)
) !default;

$fds-flex-direction: (
  "row": row,
  "row-reverse": row-reverse,
  "column": column,
  "column-reverse": column-reverse
) !default;

// --------------------------------
// Flex helper classes
// --------------------------------

.fds-flex {
  display: flex;
}

.fds-flex__row {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
}

.fds-flex--center {
  display: flex;
  justify-content: center;
}

.fds-flex--start {
  display: flex;
  justify-content: flex-start;
}

.fds-flex--end {
  display: flex;
  justify-content: flex-end;
}


// --------------------------------
// Flex Direction
// --------------------------------

@include fds-set-selectors($fds-flex-direction, 'flex-direction', 'fds-flex-direction--');

// --------------------------------
// Row Gutters
// --------------------------------

// base gutter
@each $label, $value in $fds-flex__row-gutters {
  .fds-gutter--#{$label} {
    margin-left: $value;
    margin-right: $value;

    > [class*='fds-flex__col-'] {
      padding-right: calc(-1 * #{$value});
      padding-left: calc(-1 * #{$value});
    }
  }
}

// responsive gutter
@each $breakpoint in map-keys($fds-breakpoints) {
  @include media(#{'>=' + $breakpoint}) {
    @each $label, $value in $fds-flex__row-gutters {
      .#{$breakpoint}\:fds-gutter--#{$label} {
        margin-left: $value;
        margin-right: $value;

        > [class*='fds-flex__col-'] {
          padding-right: calc(-1 * #{$value});
          padding-left: calc(-1 * #{$value});
        }
      }
    }
  }
}

// --------------------------------
// Column Spacing
// --------------------------------

[class*='fds-flex__col-'] {
  flex: 0 0 auto;
  padding-right: fds-rem($fds-flex__gutter);
  padding-left: fds-rem($fds-flex__gutter);
}

@include fds-columns($fds-flex__columns);
@include fds-columns-offsets($fds-flex__columns);
@include fds-columns-helpers;

// Display grid lines
// @if $enable-gridlines {
//   body {
//     background-image: linear-gradient(to bottom, hsla(200, 100%, 50%, .3) 1px, transparent 1px);
//     background-position: left -1px;
//     background-repeat: repeat;
//     background-size: 20px 20px;
//   }
// }
