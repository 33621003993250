@import '~primeicons/primeicons.css';

html, body {
	height: 100%;
}

body {
	margin: 0;
	font-family: "FordAntenna", sans-serif;
	background: #f2f2f1;
	line-height: 1.5;
}

body #app-content {
	margin: 0px;
}

.mar-top-30 {
	margin-top: 30px;
}

.mar-top-50 {
	margin-top: 50px;
}

.height-100 {
	height: 100%;
}

ul li {
	list-style-type: none;
}

a {
	text-decoration: none;
}

.back {
	width: 20px;
	position: absolute;
	left: 25px;
	top: 50px;
}

.back img {
	width: auto;
}

.settings-toggle-body .ui-inputswitch .ui-inputswitch-slider {
	background-color: #26a0f9;
	color: #fff;
	width: 70px;
	height: 32px;
	box-shadow: inset 0px 3px 5px 0px rgba(107, 105, 105, 0.75);
	-webkit-box-shadow: inset 0px 3px 5px 0px rgba(107, 105, 105, 0.75);
	-moz-box-shadow: inset 0px 3px 5px 0px rgba(107, 105, 105, 0.75);
}

.settings-toggle-body .ui-inputswitch-slider::before {
	position: absolute;
	color: #000;
	left: 6px;
	width: 24px;
	height: 24px;
	text-indent: -26px;
	font-size: 13px;
	top: 4px;
}

.settings-toggle-body .ui-inputswitch-slider::after {
	content: 'OFF';
	position: absolute;
	color: #000;
	top: 7px;
	left: 34px;
	font-size: 13px;
	letter-spacing: 2px;
}

.settings-toggle-body .ui-inputswitch-checked .ui-inputswitch-slider::before {
	position: absolute;
	color: #fff;
	left: 24px;
	width: 24px;
	height: 24px;
	text-indent: -26px;
	font-size: 13px;
	top: 4px;
}

.settings-toggle-body .ui-inputswitch-checked .ui-inputswitch-slider::after {
	content: 'ON';
	position: absolute;
	color: #fff;
	top: 7px;
	left: 15px;
	font-size: 13px;
	letter-spacing: 2px;
}

.settings-toggle-body .select-box-form .ui-dropdown:not(.ui-state-disabled):hover {
	border-color: inherit;
	background-color: #fff;
	color: #212121;
}

.settings-toggle-body .ui-dropdown-panel .ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
	border-color: inherit;
	background-color: #fff;
	color: #212121;
}

.settings-toggle-body .ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
	border-color: inherit;
	background-color: #fff;
	color: #212121;
}

.settings-toggle-body .ui-inputswitch .ui-inputswitch-slider:hover {
	background-color: #F6F6F6 !important;
}

.settings-toggle-body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider:hover {
	background-color: #26A0F9 !important;
}

.wallbox-settings-dialog .ui-widget-header {
	display: none;
}

.wallbox-settings-dialog .ui-widget.ui-widget-content {
	border: none;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	left: 10% !important;
}

.wallbox-settings-dialog .ui-widget.ui-widget-content h4 {
	color: #5A5A5A;
	font-stretch: condensed;
	font-size: 24px;
	font-weight: 300;
	letter-spacing: 3.33px;
	line-height: 36px;
	text-align: center;
	text-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
}

.wallbox-settings-dialog .ui-widget.ui-widget-content img {
	margin: 25px 0 20px 0;
}

.wallbox-settings-dialog .ui-widget.ui-widget-content h4 {
	padding-bottom: 25px;
}

.wallbox-settings-dialog .ui-widget.ui-widget-content button {
	margin-bottom: 30px;
}

.wallbox-settings-dialog .ui-widget.ui-button.save {
	font-size: 16px;
}

.wallbox-setting-body h3.manage-wallbox-heading {
	color: #4D4D4D;
	font-stretch: condensed;
	font-size: 35px;
	font-weight: 200;
	margin-top: 91px;
	letter-spacing: 2px;
	line-height: 45px;
	text-align: center;
	padding: 0px;
	width: 100%;
}

.wallbox-settings {
	background-color: #f7f7f7;
	background-image: url("/assets/image/settings-left-top.jpg");
	background-repeat: repeat-x;
}

.wallbox-setting-body {
	display: block;
	padding-bottom: 30px;
}

.wallbox-setting-body h3 {
	text-align: center;
	width: 80%;
	padding: 0 10%;
	margin: 30px 0 30px;
	line-height: 32px;
	font-size: 24px;
	font-weight: normal;
	color: #4D4D4D;
	letter-spacing: 1.7px;
	font-stretch: condensed;
}

.wallbox-settings .form-group {
	margin-bottom: 15px;
	width: 100%;
	position: relative;
}

.settings-toggle {
	position: relative
}


.settings-toggle-body label {
	border-bottom: 1px solid #ccc;
	padding-bottom: 20px;
	margin-bottom: 20px;
	width: 56%;
	display: block;
}

.settings-toggle-body {
	position: relative;
	margin-top: 35px;
}

.settings-toggle-body .settings-toggle-body-input {
	position: absolute;
	right: 30px;
	top: 0px;
}

.settings-toggle-body p {
	color: #787878;
	font-size: 12px;
	letter-spacing: 0.86px;
	width: 95%;
	line-height: 19px;
	font-stretch: condensed;
}

.wallbox-settings .settings-address.form-group {
	margin-bottom: 0px;
}

.wallbox-settings .mat-divider {
	margin-right: auto;
	margin-left: auto;
	width: 80%;
}

input[type="text"], input[type="search"], .wallbox-setting-body input[type="number"], .wallbox-setting-body textarea {
	padding: 5px 10px !important;
	/*font-size: 14px;*/
	/*letter-spacing: 1.17px;*/
	/*line-height: 14px;*/
	height: 50px !important;
	/*border-radius: inherit;*/
	/*font-family: FordAntennaCondMd, Arial, Helvetica, sans-serif;*/
	/*color: #4D4D4D;*/
	/*border: 1px solid #6E6E6E !important;*/
	/*box-sizing: border-box;*/
}

.wallbox-setting-body .settings-address input.location {
	margin-bottom: 20px;
}

.wallbox-setting-body .settings-form-inline {
	margin-top: 35px;
}

.wallbox-setting-body .settings-form-inline input {
	width: 24%;
	margin-right: 1.3%;
	box-sizing: border-box;
}

.wallbox-setting-body .settings-form-inline input:last-child {
	margin-right: 0px;
}

.wallbox-settings-dialog .ui-widget-header {
	display: none;
}

/* old end */


/* New Design Tab View start here */
.app-download-coursal {
	width: 320px;
	margin: 0px auto;
}

.app-download-coursal .ui-galleria-caption {
	background: none;
	color: #fff;
	width: 96%;
	text-align: center;
	left: 0px !important;
	margin-left: auto !important;
	padding: 0px !important;
	margin-right: auto !important;
	overflow: visible;
	z-index: 999999;
}

.app-download-coursal .ui-galleria-caption h4 {
	color: #FFFFFF;
	font-stretch: condensed;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 4px;
	text-transform: uppercase;
	line-height: 25px;
	margin: 0px;
	padding: 0px;
	text-shadow: 1px 1px #504f4f;
}

.app-download-coursal .ui-galleria-caption p {
	color: #FFFFFF;
	font-size: 12px;
	font-family: FordAntenna;
	letter-spacing: 0.5px;
	line-height: 18px;
	text-align: center;
	padding: 0 0 20px 0;
	width: 90%;
	margin: 0px auto;
	text-shadow: 1px 1px #504f4f;
	font-weight: lighter;
}

.app-download-coursal .ui-galleria-panel-wrapper {
	height: 220px !important;
}

.app-download-coursal .ui-galleria-panel-wrapper li img {
	width: 320px;
	opacity: 0.86;
	background: linear-gradient(180deg, rgba(37, 86, 134, 0.29) 0%, rgba(16, 43, 78, 0.96) 100%);
	-webkit-box-shadow: 0px 15px 28px -21px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 15px 28px -21px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 15px 28px -21px rgba(0, 0, 0, 0.75);
}

.app-download-coursal .ui-galleria-nav-next, .app-download-coursal .ui-galleria-nav-prev {
	display: none;
}

.app-download-coursal .ui-galleria-filmstrip-wrapper {
	background: inherit;
	height: 2px !important;
	margin: 25px 0 0 0;
}

.app-download-coursal .ui-galleria-filmstrip-wrapper ul {
	background: inherit;
	width: 320px !important;
	display: flex;
	justify-content: center;
}

.app-download-coursal .ui-galleria-frame {
	height: 2px !important;
	width: 30px !important;
	border-radius: 20px;
	background-color: #525050;
	box-shadow: inset 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 0 4px 0 #FFB100;
}

.app-download-coursal .ui-galleria-frame.ui-galleria-frame-active {
	background-color: #0061A8;
}

.app-download-coursal .ui-galleria-frame-content img {
	display: none;
}

/* New Design Location start here */
.tab-location-details.tab-location-details-mar-pad {
	padding: 16px 0px 5px 0px;
}

.tab-location-details {
	margin: 20px 0px 25px 0;
	padding: 20px 25px 17px 25px;
	border-radius: 3px;
	background-color: #FFFFFF;
	/*box-shadow: inset 0 6px 6px 0 #FFFFFF, 0 60px 60px 0 rgba(0,0,0,0.1), 0 20px 20px 0 rgba(0,0,0,0.1), 0 10px 10px 0 rgba(0,0,0,0.1);*/
	box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1);
	display: block;
}

.tab-location-details h6 {
	color: #102B4E;
	font-stretch: condensed;
	padding-bottom: 10px;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 3px;
	text-transform: uppercase;
	border-bottom: 2px solid #E6E6E6;
	line-height: 20px;
	font-style: normal;
	position: relative;
}

.tab-location-details h6 span {
	position: absolute;
	top: -6px;
	right: -8px;
}

.tab-location-details h6 span img {
	width: 27px;
}

.tab-location-details-address {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.tab-location-details-address address {
	color: #4D4D4D;
	font-stretch: condensed;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
	line-height: 24px;
	font-style: normal;
	padding-top: 5px;
	margin: 0px 0px 0px 15px;
}

.tab-location-details-address .blue-box {
	height: 40px;
	width: 40px;
	border-radius: 14px;
	background-color: #102B4E;
}

.tab-location-details-address > *:last-child {
	align-self: stretch;
}

.tab-location-details-address .oval-outer {
	display: flex;
	flex-direction: row;
}

.tab-location-details-address .oval-outer:last-child {
	margin-left: auto;
}

.tab-location-details-address .oval {
	height: 5.75px;
	width: 5.75px;
	background-color: #102B4E;
	border-radius: 50%;
}

.tab-location-button {
	margin: 90px 0 20px 0;
	padding: 0 40px;
	width: 100%;
	float: left;
	text-align: center;
	box-sizing: border-box;
}

.tab-location-button.mar-top-50 {
	margin-top: 50px;
}

.tab-location-button span {
	margin: 0px auto;
	padding: 0px;
	text-align: center;
	vertical-align: middle;
	height: 74px;
	float: none;
	color: #fff !important;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: #102B4E;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 3px;
	line-height: 20px;
	box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
	-o-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
	cursor: pointer;
	font-stretch: condensed;
}

.tab-location-button span h4 {
	color: #FFFFFF;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 3px;
	line-height: 20px;
	text-align: center;
}

/* New Design Location end here */

/* New Design Location Details start here */
.tab-location-details .tab-location-details-info {
	margin: 0px;
	padding: 0px;
	width: 100%;
	position: relative;
}

.tab-location-details .tab-location-details-info h1 {
	margin: 0px;
	padding: 0px;
	width: 90%;
	line-height: 37px;
}

.tab-location-details .tab-location-details-info-id {
	margin: 25px 0 0 0;
	padding: 0px;
	width: 100%;
}

.tab-location-details .tab-location-details-info-id .row {
	margin: 15px 0px 15px 25px;
}

.tab-location-details-info-id .row .disp-value {
	height: 23px;
	color: #102B4E;
	font-stretch: condensed;
	font-size: 19px;
	font-weight: 500;
	letter-spacing: 0.63px;
	line-height: 23px;
}

.tab-location-details-info-id .label {
	color: #4C4C4C;
	font-stretch: condensed;
	font-size: 10px;
	letter-spacing: 1px;
	line-height: 20px;
}

.tab-location-details-info h1 span {
	position: absolute;
	top: 0px;
	right: 0px;
}

.tab-location-details-info h1 span img {
	width: 27px;
}

/* New Design Access start here */
.tab-location-button.mar-top-13 {
	margin-top: 13px !important;
}

.access-control-main {
	width: 100%;
	display: inline-block;
}

.access-control {
	width: 100%;
	/*float: left;*/
	position: relative;
	display: flex;
	flex-direction: column;
}

.content-view h1.steps-access-h1 {
	padding: 31px 0 29px;
}

.access-control h5 {
	color: #4D4D4D;
	display: block;
	font-size: 16px;
	letter-spacing: 2px;
	line-height: 26px;
	font-weight: 100;
	font-stretch: condensed;
}

.access-control hr {
	width: 50%;
	border: 1px solid #E4E4E4;
	float: left;
	clear: right;
	margin-bottom: 18px;
}

.access-control .access-control-para {
	width: 100%;
	float: left;
	clear: both;
}

.tab-location-details.steps-access-details {
	padding: 22px 0px 14px 0px;
}

.access-control .access-control-para p {
	height: 48px;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 16px;
	margin:25px 0px 32px 0px;
}

.access-control .access-control-para.steps-access-control-para p {
	width: 69%;
}

.access-control .access-control-para span {
	width: 85px;
	float: right;
	margin: 0px;
	padding: 0px;
}

.access-control .settings-toggle-body .settings-toggle-body-input {
	top: -66px;
	right: 24px;
}

.access-control .settings-toggle-body .ui-inputswitch .ui-inputswitch-slider {
	color: #fff;
	width: 72px;
	border: none;
	background-color: #F6F6F6;
	height: 40px;
	border-radius: 40px;
	box-shadow: inset 0 -2px 5px 0 #FFFFFF, inset 0 2px 5px 0 rgba(0, 0, 0, 0.35), 0 10px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 2px #FFFFFF, 0 5px 10px 2px #FFFFFF, 0 1px 0 0 #FFFFFF;
}

.access-control .settings-toggle-body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
	background-color: #102B4E !important;
	box-shadow: inset 0 -2px 5px 0 #09182C, inset 0 2px 5px 0 rgba(0, 0, 0, 0.35), 0 10px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 2px #FFFFFF, 0 5px 10px 2px #FFFFFF, 0 1px 0 0 #FFFFFF;
}

.access-control .settings-toggle-body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider:hover {
	background-color: #102B4E !important;
	box-shadow: inset 0 -2px 5px 0 #09182C, inset 0 2px 5px 0 rgba(0, 0, 0, 0.35), 0 10px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 2px #FFFFFF, 0 5px 10px 2px #FFFFFF, 0 1px 0 0 #FFFFFF;
}

.access-control .settings-toggle-body .ui-inputswitch-slider::after {
	content: 'OFF';
	position: absolute;
	color: #4D4D4D;
	top: 10px;
	left: -35px;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 3px;
	line-height: 20px;
	font-stretch: condensed;
}

.access-control .settings-toggle-body .ui-inputswitch-checked .ui-inputswitch-slider::after {
	content: 'ON';
	position: absolute;
	color: #4D4D4D;
	top: 10px;
	left: -29px;
	font-size: 12px;
	letter-spacing: 3px;
	line-height: 20px;
	font-weight: 500;
	font-stretch: condensed;
}

.access-control .settings-toggle-body .ui-inputswitch-slider::before {
	position: absolute;
	color: #000;
	left: 6px;
	width: 30px;
	height: 30px;
	text-indent: -26px;
	border-radius: 38px;
	font-size: 13px;
	top: 5px;
	background: linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 100%);
	box-shadow: 0 27px 27px 0 rgba(0, 0, 0, 0.1), 0 18px 18px 0 rgba(0, 0, 0, 0.1), 0 9px 9px 0 rgba(0, 0, 0, 0.1);
}

.settings-toggle-body .ui-inputswitch-checked .ui-inputswitch-slider::before {
	position: absolute;
	color: #fff;
	left: 20px;
	width: 30px;
	height: 30px;
	text-indent: -26px;
	font-size: 13px;
	top: 5px;
	background: linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 100%);
	box-shadow: 0 27px 27px 0 rgba(0, 0, 0, 0.1), 0 18px 18px 0 rgba(0, 0, 0, 0.1), 0 9px 9px 0 rgba(0, 0, 0, 0.1);
}

/* New Design Access end here */

/* Common design for all the app steps and dashboard setup pages - starts here */
.tab-location-details-name {
	padding: 16px 20px 30px 17px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.tab-location-details-name .oval-outer {
	display: flex;
	flex-direction: row;
}

.tab-location-details-name .oval-outer:last-child {
	margin-left: auto;
}

.tab-location-details-name .oval {
	height: 5.75px;
	width: 5.75px;
	background-color: #102B4E;
	border-radius: 50%;
}

.rectangle-box {
	box-sizing: border-box;
	width: auto;
	border: 1px solid #354C6A;
	background-color: #F2F2F1;
	/*box-shadow: inset 0 6px 6px 0 #FFFFFF, 0 60px 60px 0 rgba(0,0,0,0.1), 0 20px 20px 0 rgba(0,0,0,0.1), 0 10px 10px 0 rgba(0,0,0,0.1);*/
	position: relative;
	padding: 0% 5.14% 0% 4.86%;
	/*margin: 0px 20px;*/
}

.rectangle-box-title-text {
	height: 24px;
	width: 79px;
	margin: 0% 81.11% 6.5px 2.22%;
}

.rectangle-box-title-label {
	height: 24px;
	width: 35px;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 11px;
	letter-spacing: 1px;
	line-height: 20px;
	font-weight: 100;
}

.action-button {
	height: 25.67px;
	width: 55px;
	border: 1px solid #102B4E;
	border-radius: 15.5px;
	text-align: center;
}

.edit-button {
	height: 20px !important;
	color: #102B4E !important;
	font-stretch: condensed;
	font-size: 14px !important;
	letter-spacing: 1px !important;
	line-height: 20px !important;
	text-decoration: underline !important;
}

.textbox-div {
	height: 80px;
	width: 100%;
}

.textbox-label {
	height: 20px;
	width: 100%;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 11px;
	letter-spacing: 1px;
	line-height: 20px;
	margin: 0px 0px 10px 0px;
	font-weight: 100;
}

.field-margin {
	margin: 24px 0px 0px 0px;
}

.save-button {
	height: 100px;
	width: 280px;
	margin-top: 32px;
}

.save-button-text {
	height: 16.6px;
	width: 105px;
	color: #FFFFFF;
	font-family: FordAntennaCondLt;
	font-size: 18px;
	letter-spacing: 1px;
	line-height: 13px;
	text-align: center;
	text-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
	margin: auto;
	line-height: 2;
	font-weight: 300;
}

.save-button-outer-wallbox-name {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 0px 30px 0px;
}

.close-button {
	position: absolute;
	top: 24px;
	right: 22.15px;
}

.close-action {
	margin-top: 24px;
}

.margin-top-location-text {
	margin: 70.5px 0px 0px 0px;
}

.we-have-defaulted-to {
	height: 50px;
	width: 100%;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 24px;
	text-align: center;
	font-weight: 100;
	margin: 24px 0px 24px 0px;
}

/*.line-wallbox-location {*/
/*	box-sizing: border-box;	height: 1px;*/
/*	width: 100%;*/
/*	margin: -7px 0 12px;*/
/*}*/
.line-wallbox-name {
	box-sizing: border-box;
	height: 2px;
	width: 100%;
	border: 1px solid #D8D8D8;
	/*margin: 12px 0px 0px 0px;*/
}

.wallbox-name-label {
	color: #4D4D4D;
	font-family: FordAntennaCondLt;
	font-size: 35px;
	letter-spacing: 3.5px;
	line-height: 45px;
	text-align: center;
	padding-bottom: 10px;
	text-transform: uppercase;
	padding: 16px 0px 0px 0px;
	clear: both;
}

.wallbox-location-label {
	color: #102b4e;
	font-stretch: condensed;
	padding-bottom: 10px;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 3px;
	text-transform: uppercase;
	line-height: 20px;
	font-style: normal;
	clear: both;
}

.location-box-main-div {
	height: auto;
	width: auto;
	background-color: #F7F7F7;
	background: linear-gradient(180deg, #FFFFFF 0%, #F1F1F1 100%);
	box-shadow: inset 0 6px 6px 0 #FFFFFF, 0 60px 60px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	padding: 16px 20px 30px 17px;
}

.rectangle-box-wallbox-location .text {
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 24px;
	text-align: center;
	font-weight: 100;
}

.action-button {
	float: right;
}

.rectangle-box-wallbox-location .action-button {
	float: none;
}

.line-5 {
	box-sizing: border-box;
	height: 1px;
	width: 100%;
	border: 1px solid #D5D5D5;
	margin: 25px 0px 12px 0px;
}

.wallbox-id {
	height: 25px;
	width: 100%;
	color: #4D4D4D;
	font-stretch: condensed;
	font-size: 12px;
	font-weight: 300;
	letter-spacing: 2.57px;
	line-height: 25px;
	text-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}

.wallbox-id-span {
	width: 70%;
}

.pin {
	height: 25px;
	width: 30%;
	color: #4D4D4D;
	font-size: 12px;
	letter-spacing: 2.57px;
	line-height: 25px;
	text-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
	float: right;
}

.wallbox-id-display {
	height: 25px;
	width: 100%;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-weight: 300;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 24px;
}

.edit-div {
	/*float: right;*/
	width: 100%;
}

.edit-user-button {
	margin: 32px 13%;
	padding: 10px;
	border-radius: 20px;
	background-color: #102B4E;
	text-align: center;
	box-shadow: 0 1px 6px 0 #BFBFBF, 0 30px 30px 0 rgba(0, 0, 0, 0.15), 0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1);
}

.edit-user-button-text {
	color: #FFFFFF;
	font-family: "FordAntennaCondLt";
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 1px;
	line-height: 13px;
	text-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}

/*.dashboard-accordian .location-margin {*/
/*	margin: 40px 0px 5px 6.5px;*/
/*}*/
.float-right {
	float: right;
}

.clear-both {
	clear: both;
}

form .p-dropdown {
	height: 50px !important;
	border: 1px solid #6E6E6E !important;
}

.p-dropdown .p-dropdown-trigger {
	width: 2.5em !important;
}

.p-dropdown .p-dropdown-label {
	padding-top: 13px !important;
}

.ui-slider {
	height: 10px !important;
	width: 100%;
	border-radius: 4.18px !important;
	background: linear-gradient(180deg, #F6F5F7 0%, #EAE7ED 100%) !important;
	box-shadow: inset 0 0 1px 0 rgba(95, 101, 112, 0.56) !important;
	border: none !important;
}

.ui-slider .ui-slider-range {
	height: 9px !important;
	border-radius: 4.18px !important;
	background: linear-gradient(270deg, #0275EB 0%, #0B47BA 100%) !important;
	box-shadow: inset 0 0 1px 0 rgba(95, 101, 112, 0.56) !important;
}

div.ui-slider .ui-slider-handle {
	border-radius: 50% !important;
	margin-top: -4px !important;
	background-image: url("/assets/image/knob.svg") !important;
	height: 19px !important;
	width: 19px !important;
}

.ngx-slider .ngx-slider-pointer {
	border-radius: 50% !important;
	margin-top: -4px !important;
	background-image: url("/assets/image/knob.svg") !important;
	height: 19px !important;
	width: 19px !important;
	z-index: 3;
	top: -1px !important;
	box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.15), 0 9px 9px 0 rgba(0, 0, 0, 0.15), 0 4px 4px 0 rgba(0, 0, 0, 0.15);
}

.ngx-slider .ngx-slider-bar {
	height: 10px !important;
	width: 100%;
	border-radius: 4.18px !important;
	background: linear-gradient(180deg, #f6f5f7 0, #eae7ed 100%) !important;
	box-shadow: inset 0 0 1px 0 rgba(95, 101, 112, .56) !important;
	border: none !important;
}

.ngx-slider .ngx-slider-selection {
	z-index: 2;
	background: linear-gradient(270deg, #0275eb 0, #0b47ba 100%) !important;
	box-shadow: inset 0 0 1px 0 rgba(95, 101, 112, 0.56) !important;
}

.ngx-slider .ngx-slider-pointer:after {
	content: none !important;
}

.ngx-slider .ngx-slider-pointer.ngx-slider-active {
	outline-style: none !important;
}

/* Common design for all the app steps and dashboard setup pages - ends here */

.ui-tooltip-text.ui-shadow.ui-corner-all {
	background-color: #a8a3a3;
}

.ui-tabview.ui-tabview-top > .ui-tabview-nav {
	padding: 0px 0px 0px 0px !important;
}

/* Wallbox Details dashboard start here */
.top-bar-dashboard {
	height: 36px;
	line-height: 36px;
	background-color: #102B4E;
	box-shadow: 0 0.5px 0 0 #C8C8C8, 0 24px 38px 3px rgba(0,0,0,0.04), 0 9px 46px 8px rgba(0,0,0,0.03), 0 11px 15px -7px rgba(0,0,0,0.05);
	margin: auto;
	align-items: center;
	text-align: center;
}
.wallbox-name {
	height: 16px;
	color: #F2F2F2;
	font-family: FordAntenna;
	font-size: 12px;
	font-weight: 300;
	letter-spacing: 1px;
	text-align: center;
}
.top-bar-dashboard .list-icon {
	position: absolute;
	right: 17px;
	top: 3px;
}
.top-bar-title {
	color: #4D4D4D;
	font-stretch: condensed;
	font-size: 26px;
	letter-spacing: 3px;
	line-height: 36px;
	text-align: center;
	margin: 22px 0px 22px 0px;
}

.pos-relative {
	position: relative;
}

.wallbox-dashboard {
	margin: -1px 0px 0px 0px;
}

.wallbox-dashboard .wallbox-banner {
	float: left;
	width: 100%;
	position: relative;
}

.wallbox-dashboard .wallbox-banner .wallbox-banner-image {
	position: relative;
	text-align: center;
	overflow: hidden;
}

.wallbox-dashboard .wallbox-banner .wallbox-banner-image img {
	width: 100%;
	position: relative;
	display: inline;
}

.rectangle-outer img {
	width: 128px !important;
	height: 19px !important;
}

.rectangle-offline-80A-bg img {
	width: 30px !important;
	height: 8px !important;
}

.wallbox-dashboard .wallbox-banner-status {
	position: absolute;
	width: 100%;
	text-align: center;
	z-index: 1;
}

.wallbox-dashboard .wallbox-banner-status p {
	color: #253C67;
	font-stretch: condensed;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 2px;
	line-height: 24px;
	text-align: center;
}

.wallbox-lock-unlock {
	position: absolute;
	width: 100%;
	text-align: center;
	bottom: -100px;
	height: 189px;
	background-size: 198px;
}

.wallbox-lock-unlock .wallbox-lock-unlock-main {
	box-sizing: border-box;
	height: 152px;
	width: 152px;
	/*border: 2px solid #102B4E;*/
	display: inline-block;
	background: #FDFDFD;
	position: relative;
	box-shadow: inset 0 6px 6px 0 #FFFFFF, 0 60px 60px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1);
}

.wallbox-lock-unlock .wallbox-lock-unlock-main img {

}

.wallbox-lock-unlock .wallbox-lock-unlock-main span {
	width: 100%;
	float: left;
	color: #102B4E;
	font-size: 18px;
	font-stretch: condensed;
	font-weight: 500;
	letter-spacing: 2px;
	text-align: center;
}

.wallbox-lock-unlock .wallbox-lock-unlock-main span.pad-top-40 {
	padding-top: 40px;
}

.wallbox-lock-unlock .wallbox-lock-unlock-main span img {
	text-align: center;
	margin-bottom: 5px;
}

.wallbox-support {
	position: absolute;
	width: 100%;
	text-align: center;
	bottom: 100px;
	background-size: 198px;
}

.mobile-container-dashboard {
	margin: 0px auto;
}

.pos-image-icon {
	right: -10px !important;
}

.dashboard-access {
	width: 100%;
	margin: 40px 0 20px 0;
	padding: 0 6px;
}

.dashboard-access .access-control hr {
	width: 50%;
	border: 1px solid #E4E4E4;
	float: left;
	clear: right;
	margin-bottom: 14px;
}

.dashboard-access .access-control .settings-toggle-body .settings-toggle-body-input {
	top: -66px;
	right: 28px;
}

.upperCase {
	text-transform: uppercase !important;
}

.imgValignMiddle {
	vertical-align: middle;
}

.ui-accordion-header {
	height: 55.1%;
	width: 100%;
	background-color: #F3F3F3;
	box-shadow: inset 0 6px 6px 0 #FFFFFF, 0 60px 60px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1);
}

.display-flex {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.ui-chkbox .ui-chkbox-box {
	height: 16px !important;
	width: 16px !important;
	background-color: rgba(225, 225, 225, 0.42) !important;
	border: 2px solid #102B4E !important;
	color: #102B4E !important;
}

.ui-chkbox-icon {
	font-size: 12px !important;
}

.ml10 {
	margin-left: 16px;
}

.custom-toast .p-toast-message-content {
	background-color: #FFFFFF;
	padding: 20% 7% 10% 7%;
}

.p-toast-close-icon {
	display: none !important;
}

.custom-toast .edit-user-button {
	margin: 25% 10% 10% 10% !important;
}

.custom-toast .delete-user-button {
	margin: 0% 10% !important;
	text-align: center;
	font-family: "FordAntennaCondLt";
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 1px;
	line-height: 13px;
}

.custom-toast-ajax {
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 24px;
	background-color: #F7F7F7;
	margin: 0px !important;
	left: 0 !important;
	top: 0 !important;
	width: 100% !important;
	height: auto;
}

.custom-toast-ajax .rectangle {
	height: 7px;
	margin-top: 4px;
}

.custom-toast-ajax .icon {
	font-size: 20px;
	/*padding: 15px 10px;*/
	margin: auto 10px;
	display: inline-block;
}

.custom-toast-ajax .p-toast-message {
	margin: 0px !important;
	/*height: 60px !important;*/
}

.custom-toast-ajax .p-toast-message-content {
	padding: 0px !important;
	display: block !important;
}

.p-toast-icon-close {
	display: none !important;
}

.toast-text {
	color: #4D4D4D;
	font-family: FordAntenna;
	display: flex;
	flex-direction: row;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 24px;
	margin: 15px 10px;
	/*padding: 10px;*/
}

.p-toast-top-center {
	transform: none !important;
}

.eGreen {
	background-color: #008200;
}

.eRed {
	background-color: #D62D14;
}

.eGreenC {
	color: #008200;
}

.eRedC {
	color: #D62D14;
}

input:focus {
	outline: none !important;
	box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1) !important;
	color: #102B4E;
}

.ui-float-label > input:focus ~ label, .ui-float-label > input.ui-state-filled ~ label, .ui-float-label > .ui-inputwrapper-focus ~ label, .ui-float-label > .ui-inputwrapper-filled ~ label {
	top: -40% !important;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 11px !important;
	letter-spacing: 1px;
	line-height: 20px;
	padding: 0px;
}

.ui-float-label > label {
	color: #4D4D4D !important;
	font-family: FordAntenna;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 27px;
	padding-left: 15px;
}

.disabledInput {
	background-color: #f2f2f1 !important;
}

.disabledButton {
	background-color: #6E6E6E !important;
}

.p-dropdown-panel .p-dropdown-list {
	padding: 0px !important;
}

.p-dropdown-items .ui-corner-all {
	border-radius: 0px !important;
}

.rectangle-box-wallbox-location .ui-float-label > input:focus ~ label, .rectangle-box-wallbox-location .ui-float-label > input.ui-state-filled ~ label, .rectangle-box-wallbox-location .ui-float-label > .ui-inputwrapper-focus ~ label, .rectangle-box-wallbox-location .ui-float-label > .ui-inputwrapper-filled ~ label {
	top: 5% !important;
}

.rectangle-box-wallbox-location .p-inputtext {
	font-family: FordAntenna !important;
	font-size: 1.6rem !important;
	line-height: 2.6rem !important;
	padding: 5px 10px !important;
	letter-spacing: 1px !important;
	position: relative !important;
	z-index: 2 !important;
	min-height: 5rem !important;
	width: 100% !important;
	-webkit-appearance: none !important;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
	border-radius: .3rem !important;
	border: 1px solid #6e6e6e !important;
	padding: 0 1.5rem !important;
	color: #4d4d4d !important;
	resize: none !important;
	transition: box-shadow .3s ease-in-out, background-color .3s ease-in-out !important;
}

.p-autocomplete-panel {
	background-color: #fff!important;
	border-radius: 2px!important;
	box-shadow: 0 0 24px 0 rgba(0,0,0,.22),0 24px 24px 0 rgba(0,0,0,.3)!important;
}

.p-autocomplete-item {
	font-family: FordAntenna !important;
	font-size: 1.6rem !important;
	line-height: 2.6rem !important;
	padding: 5px 10px !important;
	letter-spacing: 1px !important;
	width: 100% !important;
	border-radius: .3rem !important;
	white-space: normal !important;
}

.ui-state-highlight {
	background-color: #a9a9a9 !important;
}

.content-position-insights {
	position: absolute;
	top: 70px;
	left: 0px;
	right: 0px;
	height: auto;
	margin: 0px 0px 12px 0px;
	background: linear-gradient(180deg, #E7E7E7 0%, rgba(216, 216, 216, 0) 97.42%);
	overflow: auto;
}

.content-position-max-current {
	position: absolute;
	top: 0;
	left: 0px;
	right: 0px;
	height: auto;
	overflow: auto;
	background: linear-gradient(180deg, #E7E7E7 0%, rgba(216, 216, 216, 0) 97.42%);
	bottom: 136px;
}

/*manage users starts here*/
.manage-all-users .dashboard-accordian {
	float: left;
	width: 100%;
	padding: 0px;
	margin: 0px;
}

.manage-all-users .title {
	height: 26px;
	color: #102B4E;
	font-stretch: condensed;
	font-size: 16px;
	letter-spacing: 2px;
	line-height: 26px;
	margin: 25px 0px;
}

.manage-all-users .edit-box {
	padding: 0px 12px 0px 15px;
}

.manage-all-users .name-sent {
	height: 20px;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 20px;
	word-break: break-all;
	text-transform: lowercase;
}

.manage-all-users .rectangle {
	width: 55px;
	border: 1px solid #102B4E;
	border-radius: 15.5px;
	text-align: center;
	cursor: pointer;
	right: 30px;
}

.manage-all-users .line-copy {
	height: 1px;
	transform: scaleX(-1);
	background-color: #C8C8C8;
}

.manage-all-users .edit-user-bg {
	padding: 22px 12px 22px 15px;
	background-color: rgba(225, 225, 225, 0.42);
}

.manage-all-users .wallbox-list {
	height: 58px;
	padding: 30px 0px;
}

.manage-all-users .wallbox-name {
	width: 95%;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 13px;
	letter-spacing: 1px;
	line-height: 26px;
}

.manage-all-users .line-copy-wallbox, .address-list-main-div .line-copy-wallbox {
	border: 0.5px solid #CDCDCD;
	margin: 3% 1% 3% 0%;
}

.manage-all-users .edit-user-button {
	height: 40px;
	margin: 10% 20%;
	padding: 8px;
	border-radius: 20px;
	background-color: #102B4E;
	text-align: center;
	box-shadow: 0 1px 6px 0 #BFBFBF, 0 30px 30px 0 rgba(0, 0, 0, 0.15), 0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
}

.manage-all-users .edit-user-button-text {
	color: #FFFFFF;
	font-family: "FordAntennaCondLt";
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 1px;
	line-height: 13px;
	text-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}

.manage-all-users .rectangle-resend {
	padding: 15px 15px 0px 15px;
	margin-top: 15px;
	opacity: 0.73;
	background-color: rgba(255, 255, 255, 0.73);
}

.manage-all-users .resend-text {
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 11px;
	letter-spacing: 1px;
	line-height: 20px;
	text-align: center;
}

.manage-all-users .delete-resend-user-button {
	height: 40px;
	margin-top: 20px;
	text-align: center;
}

.manage-all-users .delete-user-button-text {
	height: 0.89%;
	width: 29.17%;
	padding: 10px;
	color: #102B4E;
	font-stretch: condensed;
	font-size: 18px;
	letter-spacing: 1px;
	line-height: 13px;
	text-align: right;
	text-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}

.manage-all-users .edit {
	color: #102B4E;
	font-stretch: condensed;
	font-size: 9px;
	font-weight: 500;
	letter-spacing: 0.75px;
	line-height: 24px;
	text-align: center;
}

.manage-all-users .add-button {
	height: 100px;
	width: 80%;
	margin: 10%;
	text-align: center;
}

.manage-all-users .delete-user-button {
	height: 40px;
	margin: 0% 20%;
	text-align: center;
}

.profile-img {
	height: 24px;
	width: 24px;
	/*border-radius: 50%;*/
}
.d-flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.padd-l15 {
	padding-left: 15px;
}

.row-padd {
	padding: 10px 0px;
	height: 56px;
}

.u-line {
	text-decoration-line: underline;
}

.station-sharing-not-avail {
	height: 60px;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 20px;
}

/*manage user ends here*/

/*Delete users dialog box starts here*/
.this-is-the-manuf {
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 10px;
	letter-spacing: 1px;
	line-height: 20px;
	padding-top: 5px;
}

.important-informatio {
	color: #4D4D4D;
	font-stretch: condensed;
	font-size: 12px;
	font-weight: 300;
	letter-spacing: 2.57px;
	line-height: 25px;
	text-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}

/*Delete users dialog box ends here*/
/*Generelly used buttons starts here*/
.oval-exclamation::before {
	display: inline-block;
	content: "!";
	border-radius: 50%;
	width: 24px;
	height: 24px;
	line-height: 22px;
	text-align: center;
	color: #FFFFFF;
	background-color: #C80018;
	font-stretch: condensed;
}

.clear-icon {
	border: 1px solid transparent;
	background-color: transparent;
	display: inline-block;
	vertical-align: middle;
	outline: 0;
	cursor: pointer;
}

.clear-icon:after {
	content: "x";
	font-size: 18px;
	display: block;
	width: 25px;
	height: 25px;
	position: absolute;
	background-color: #9B9B9B;
	z-index: 1;
	right: 35px;
	top: 0;
	bottom: 0;
	margin: auto;
	padding: 2px;
	border-radius: 50%;
	text-align: center;
	color: white;
	font-weight: normal;
	box-shadow: 0 0 2px #E50F0F;
	cursor: pointer;
}

.autocomplete-address:not(:valid) ~ .clear-icon {
	display: none;
}

.rectangle-box-wallbox-location {
	color: #102B4E;
	font-family: FordAntenna;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 45px;
}

.ui-autocomplete-panel .ui-autocomplete-list {
	padding: 0px !important;
}

.address-list-main-div {
	width: 100%;
}

.address-list-main-div .address {
	color: #4D4D4D;
	font-family: FordAntenna;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 27px;
	font-size: 14px;
	letter-spacing: 1px;
	margin: 10px 30px 10px 15px
}

.autocomplete-address:not(:valid) ~ .clear-icon {
	/*display: none;*/
}

.auto-complete-main button {
	position: absolute;
	margin: 22px 0px 0px 0px;
}

.clear-icon {
	position: relative;
	padding: 10px;
}

.app-steps-dialog .p-dialog.ui-widget .p-dialog-content {
	background: #f0f8ff;
}

.app-steps-dialog .ui-widget-content {
	background: #f0f8ff;
	margin: 10px 0px;
}

/*page top bar style*/
/*.top-bar {*/
/*	display: flex;*/
/*	justify-content: space-around;*/
/*	!*height: 60px;*!*/
/*	width: auto;*/
/*	background-color: #f7f7f7;*/
/*	!*padding: 0px 3.39% 0px 3.39%;*!*/
/*	margin: 0px 0px 0px 20px;*/
/*	height: 36px;*/
/*}*/
/*.top-bar .back-arrow {*/
/*	width: 36px;*/
/*	height: 32px;*/
/*}*/
/*.top-bar .title {*/
/*	height: 100%;*/
/*	width: 100%;*/
/*	color: #253C67;*/
/*	font-family: FordAntennaCondMd;*/
/*	font-size: 12px;*/
/*	font-weight: 500;*/
/*	letter-spacing: 3px;*/
/*	line-height: 36px;*/
/*	text-align: center;*/
/*	text-shadow: 0 2px 10px 0 rgba(0,0,0,0.5);*/
/*}*/

.top-bar {
	display: flex;
	justify-content: space-around;
	/*height: 60px;*/
	width: auto;
	background-color: #FFFFFF;
	/*padding: 0px 3.39% 0px 3.39%;*/
	margin: 17px auto;
	height: 36px;
}

.top-bar .back-arrow {
	width: 36px;
	height: 32px;
	position: absolute;
	left: 4%;
}

.top-bar .title {
	width: 100%;
	height: 100%;
	color: #4D4D4D;
	font-stretch: condensed;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 36px;
	text-align: center;
}

/*app steps screen design starts here*/
.swiper-pagination {
	width: 100%;
	bottom: 14px;
}

.swiper-pagination-bullet {
	width: 24px !important;
	height: 2px !important;
	border-radius: 1px !important;
	background-color: #102B4E !important;
	margin-left: 5px;
}

.swiper-slide.swiper-slide-prev {
	width: 100% !important;
}

.swiper-slide.swiper-slide-active {
	width: 100% !important;
}

.swiper-slide.swiper-slide-next {
	width: 100% !important;
}

.swiper-wrapper {
	box-sizing: border-box;
}

/*.swiper-wrapper div:first-child{*/
/*	margin-left: 20px;*/
/*}*/
.swiper-slide img {
	/*box-shadow: 0 23px 23px 0 rgba(0,0,0,0.2), 0 15px 15px 0 rgba(0,0,0,0.1), 0 7px 7px 0 rgba(0,0,0,0.1);*/
	/*-webkit-box-shadow: 0 23px 23px 0 rgba(0,0,0,0.2), 0 15px 15px 0 rgba(0,0,0,0.1), 0 7px 7px 0 rgba(0,0,0,0.1);*/
	/*-moz-box-shadow: 0 23px 23px 0 rgba(0,0,0,0.2), 0 15px 15px 0 rgba(0,0,0,0.1), 0 7px 7px 0 rgba(0,0,0,0.1);*/
	/*-o-box-shadow: 0 23px 23px 0 rgba(0,0,0,0.2), 0 15px 15px 0 rgba(0,0,0,0.1), 0 7px 7px 0 rgba(0,0,0,0.1);*/
	/*opacity: 0.75;*/
	/*background: linear-gradient(180deg, rgba(37,86,134,0.29) 0%, rgba(16,43,78,0.96) 100%);*/
	/*height: 204px;*/
	/*width: 312px;*/
	position: relative;
	border-radius: 2px;
	/*background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%);*/
	/*box-shadow: 0 16px 24px 2px rgba(0,0,0,0.04), 0 6px 30px 5px rgba(0,0,0,0.03), 0 8px 10px -5px rgba(0,0,0,0.05);*/
}

.swiper-images {
	/*background: linear-gradient(180deg, rgba(37,86,134,0.29) 0%, rgba(4, 114, 255, 0.76) 100%);*/
	/*height: 275px;*/
	width: 100%;
}

/*app steps screen design ends here*/

.p-toast-center {
	width: 85% !important;
}

.displayBlock {
	display: block;
}

.displayHide {
	display: none !important;
}

.access-control-perm {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: auto;
	margin: 20px 0px;
}

.access-control-perm h5 {
	height: 20px;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 20px;
}

.access-control-perm-dashboard {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: auto;
	margin: 0px 0px 28px 0px;
}

.access-control-perm-dashboard h5 {
	height: 20px;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 20px;
}

.auto-unlock-perm-dashboard {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: auto;
	margin: 28px 0px;
}

.auto-unlock-perm-dashboard h5 {
	height: 20px;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 20px;
}

.tab-location-details-name {
	padding: 16px 20px 30px 17px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.display-flex .oval-outer {
	display: flex;
	flex-direction: row;
}

.display-flex .oval-outer:last-child {
	margin-left: auto;
}

.display-flex .oval {
	height: 5.75px;
	width: 5.75px;
	background-color: #102B4E;
	border-radius: 50%;
}

.marginTop25 {
	margin: 25px 0px 25px 0px;
}

.margin30Top {
	margin: 30px 0px 0px 0px;
}

.margin50TopBottom {
	margin: 50px 0px;
}

.margin30TopBottom {
	margin: 30px 0px;
}

.margin40TopBottom {
	margin: 30px 0px;
}

.margin30Bottom {
	margin: 0px 0px 30px 0px;
}

.padding40Bottom {
	margin: 0px 0px 40px 0px;
}


.ui-sidebar-bottom.ui-sidebar-sm {
	height: auto !important;
}

.ui-sidebar {
	padding: 0em 0em 0em 0em !important;
}

.p-component-overlay {
	background-color: #FFFFFF !important;
	opacity: .5;
}
.app-setup-dialog .p-component-overlay {
	background-color: transparent !important;
	opacity: 1 !important;
}

.p-dialog-content {
	background-color: #FFFFFF !important;
	border-radius: 2px !important;
	box-shadow: 0 0 24px 0 rgba(0,0,0,.22),0 24px 24px 0 rgba(0,0,0,.3)!important;
}

/*charge events starts here*/
.charge-events {
	margin: 10px 0px;
}

.charge-events .title {
	height: 26px;
	width: auto;
	color: #2b4362;
	font-stretch: condensed;
	font-size: 17px;
	letter-spacing: 2px;
	line-height: 10px;
}

.charge-events .row {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 16px 0px;
}

.charge-events .charge-data {
	display: flex;
	flex-direction: column;
	margin: 0px 16px;
}

.charge-events .display-no-events-msg {
	/*height: 20px;*/
	color: #4D4D4D;
	font-family: FordAntenna;
	font-weight: 300;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 20px;
	width: 100%;
	text-align: center;
}

.display-no-discharge-msg {
	height: 16px;
	width: 100%;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-weight: 300;
	font-size: 10px;
	letter-spacing: 0.8px;
	line-height: 16px;
	text-align: center;
}

.charge-data .unit {
	height: 20px;
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 20px;
}

.charge-data .duration {
	height: 16px;
	color: #6E6E6E;
	font-family: FordAntenna;
	font-size: 10px;
	letter-spacing: 0.8px;
	line-height: 16px;
}

.charge-events .divider {
	height: 1px;
	width: auto;
	transform: scaleX(-1);
	background-color: #C8C8C8;
}

/*charge events ends here*/
.content-position {
	/*position: absolute;*/
	/*top: 70px;*/
	left: 0px;
	right: 0px;
	/*height: 100%;*/
	height: 100%;
	overflow: auto;
	/*background: linear-gradient(180deg, #E7E7E7 0%, rgba(216,216,216,0) 97.42%);*/
	background-color: #F4F4F4;
	box-shadow: inset 0 8px 10px 1px rgba(0, 0, 0, 0.04), inset 0 -3px 14px 2px rgba(0, 0, 0, 0.03), inset 0 -2px 5px 0 rgba(0, 0, 0, 0.05);
	/*bottom: 50px;*/
}

.bottom-fixed {
	position: fixed;
	bottom: 0px;
	/*height: 80px;*/
	left: 0px;
	right: 0px;
	/*overflow: hidden;*/
	text-align: center;
	/*background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%);*/
	/*box-shadow: 0 24px 38px 3px rgba(0,0,0,0.04), 0 9px 46px 8px rgba(0,0,0,0.03), 0 11px 15px -7px rgba(0,0,0,0.05);*/
}

.got-it {
	margin: 16px 0px;
}

.line-copy-wallbox {
	border: 0.5px solid #CDCDCD;
	margin: 2% 1% 2% 0%;
	width: 100%;
}

.line-copy-info {
	border: 0.5px solid #CDCDCD;
	width: 100%;
}

.line-height-two {

}

.line-accordion {
	height: 0.9px;
	width: auto;
	background-color: #C8C8C8;
	/*margin: 0px 25px 0px 25px;*/
	position: absolute;
	left: 25px;
	right: 25px;
}

.line-accordion-inside {
	height: 1px;
	width: auto;
	background-color: #C8C8C8;
}

.red-bar-alert {
	height: 8px;
	width: 100%;
	border-radius: 8px 8px 0 0;
	background-color: #D92E14;
}

.red-bar-warning {
	height: 8px;
	width: 100%;
	border-radius: 8px 8px 0 0;
	background-color: #DA7029;
	/* background-color: #BA4E00 */
}

.blue-bar-info {
	height: 8px;
	width: 100%;
	border-radius: 8px 8px 0 0;
	background-color: #102B4E;
}

/*Station setup screen starts here*/
.station-setup {
	padding: 0px 25px;
	display: flex;
	flex-direction: column;
	position: relative;
	height: auto;
	/*align-items: center;*/
}

.station-setup h1 {
	color: #4D4D4D;
	font-stretch: condensed;
	font-size: 28px;
	font-weight: 300;
	letter-spacing: 3px;
	line-height: 36px;
	text-align: center;
	padding: 30px 0px 26px 0px;
	margin: 0px auto;
}

.station-setup .description {
	color: #4D4D4D;
	font-family: FordAntenna;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 20px;
	text-align: center;
	margin: 0px 25px 40px 25px;
}

.station-setup-content .row {
	margin: 25px 0px;
}

.station-setup-content .row .label {
	/*height: 16px;*/
	color: #6E6E6E;
	font-family: FordAntenna;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 16px;
	margin: 0px 0px 10px 0px;
}

.station-setup-content .row .disp-value {
	color: #4D4D4D;
	font-stretch: condensed;
	font-size: 20px;
	font-weight: 300;
	letter-spacing: 2px;
	line-height: 28px;
}

.station-setup-content .row .disp-value-read-only {
	color: #4D4D4D;
	font-stretch: condensed;
	font-size: 20px;
	font-weight: 300;
	letter-spacing: 2px;
	line-height: 75px;
}


.margin-next {
	bottom: 81px;
}

/*Station setup screen ends here*/

.dashboard-chargerate {
	display: flex;
	flex-direction: column;
	margin: 0px 0px 16px 0px;
	/*padding: 0 0 0 14px;*/
	width: 100%;
}

.dashboard-chargerate .charge {
	height: 36px;
	color: #102B4E;
	font-stretch: condensed;
	font-size: 28px;
	font-weight: bold !important;
	letter-spacing: 1px;
	line-height: 36px;
	text-align: center;
	margin: 0px;
}

.dashboard-chargerate .unit {
	height: 24px;
	width: 13px;
	color: #102B4E;
	font-stretch: condensed;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 24px;
	text-align: center;
}

.dashboard-chargerate .row {
	display: flex;
	flex-direction: row;
}

.dashboard-chargerate span {
	height: 24px;
	color: #102B4E;
	font-stretch: condensed;
	font-size: 16px;
	letter-spacing: 1px;
	line-height: 24px;
	margin: 0px 0px 24px 0px;
}

.dashboard-chargerate .icon {
	margin-left: 10px;
}

.dashboard-chargerate .unit-value {
	height: 21px;
	width: 36.26px;
	color: #4D4D4D;
	font-stretch: condensed;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 1.13px;
	line-height: 21px;
	padding: 0px 0px 0px 4px;
}

.max-charge-rate {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 29px;
	margin: 16px 0px 16px 0px;
}

.max-charge-rate .row {
	height: 12px;
}

.max-charge-rate .text {
	color: #102B4E;
	font-stretch: condensed;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 1px;
	height: 20px;
	line-height: 20px;
}

.max-charge-rate .slider {
	width: 80%;
	margin: 0px 28px 0px 20px;
}

.details-box {
	display: flex;
	flex-direction: column;
	margin: 35px 0px;
}

.details-box .heading {
	height: 24px;
	color: #102B4E;
	font-stretch: condensed;
	font-size: 16px;
	letter-spacing: 1px;
	line-height: 24px;
	margin: 0px 0px 24px 0px
}

.details-box .label {
	color: #6E6E6E;
	font-family: FordAntenna;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 16px;
	margin: 12px 0px 4px 0px;
}

.details-box .text {
	color: #4D4D4D;
	font-stretch: condensed;
	font-size: 20px;
	font-weight: 300;
	letter-spacing: 2px;
	line-height: 28px;
}

.divider {
	height: 2px;
	width: 311px;
}

.add-charge-button {
	padding-left: 1.5rem;
}

.ui-widget-content {
	border-radius: 2px !important;
	background-color: #FFFFFF !important;
	box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3) !important;
}

.app-setup-dialog .ui-widget-content {
	width: 300px !important;
}

.app-setup-dialog .p-dialog {
	/*margin-left : -150px !important;*/
	/*left: 50% !important;*/
}

.app-setup-dialog .p-dialog .p-dialog-content {
	padding: 1em 2em !important;
}

/*overriding the common styles of FDS*/
.fds-layout-grid {
	/*padding-left: 0px;*/
	/*padding-right: 0px;*/
	padding: 0px;
	background-color: #FFFFFF;
}

.fds-layout-grid__inner {
	grid-gap: 0px;
}

.fds-accordion .fds-accordion__button {
	font-stretch: condensed;
	height: 78px;
}

.fds-accordion.fds-color__bg--light .fds-accordion__button {
	background-color: #F6F6F6 !important;
}

.fds-accordion.fds-color__bg--light .fds-accordion__button * {
	color: #2B4362 !important;
	/*height: 20.28px;*/
	color: #102B4E;
	font-stretch: condensed;
	font-size: 20px;
	font-weight: 300;
	letter-spacing: 2px;
	line-height: 28px;
}

.fds-secondary-button__button .fds-secondary-button__text {
	white-space: normal !important;
}

.fds-toggle {
	padding: 0rem 0rem 0rem 2rem;
}

.fds-accordion__body {
	overflow: scroll !important;
}


.fds-text-field {
	/*padding-top: 0px;*/
}

.fds-accordion .fds-accordion__panel {
	margin-bottom: 0px;
}

.fds-secondary-button__button {
	margin: auto;
}



.fds-tabs__tab-text {
	height: 20px;
	color: #4D4D4D;
	font-stretch: condensed;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 20px;
	text-align: center;
	padding: 0 1.2rem 2rem 1.2rem;
}

.fds-accordion.fds-color__bg--light .fds-accordion__body {
	background-color: #f4f4f4;
	box-shadow: inset 0 2rem 2rem 0 rgba(0, 0, 0, 0.03);
	/*box-shadow: inset 0 8px 10px 1px rgba(0,0,0,.04), inset 0 -3px 14px 2px rgba(0,0,0,.03), inset 0 -2px 5px 0 rgba(0,0,0,.05);*/
}

.fds-accordion .fds-accordion__content {
	margin: 2rem 25px;
	padding: 0px;
}

.removeSpace {
	margin: -2rem 25px !important;
}

.fds-text-field.fds-text-field--valid .fds-text-field__label-icon {
	display: none !important;
}

.fds-text-field.fds-text-field--valid .fds-text-field__input {
	border-color: #6e6e6e !important;
}

.fds-toggle.fds-toggle--active .fds-toggle__input-wrapper {
	border-radius: 21.33px;
	background: linear-gradient(0deg, #0142BD 0%, #0275EB 100%);
	box-shadow: inset 0 3px 4px 0 rgba(0, 0, 0, 0.4), inset 0 -2px 4px 0 rgba(204, 221, 255, 0.5), 0 4px 11px 0 rgba(0, 0, 0, 0.45), 0 -4px 4px 0 rgba(174, 208, 255, 0.2);
}

.fds-toggle__input-wrapper {
	border-radius: 21.33px;
	background-color: #6E6E6E;
	box-shadow: inset 0 3px 4px 0 rgba(0, 0, 0, 0.4), inset 0 -1px 2px 0 rgba(255, 255, 255, 0.1), inset 0 -2px 6px 0 rgba(255, 255, 255, 0.3), 0 4px 11px 0 rgba(0, 0, 0, 0.45), 0 1px 3px 0 rgba(255, 255, 255, 0.5), 0 -4px 4px 0 rgba(255, 255, 255, 0.2);
}

.fds-accordion .fds-accordion__panel.fds-accordion--expanded .fds-accordion__body {
	height: auto !important;
}

.fds-checkbox .fds-type--checkbox-label {
	font-weight: 400 !important;
	height: 20px;
	font-family: FordAntenna;
	color: #4D4D4D;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 20px;
}

.fds-checkbox.fds-checkbox--disabled .fds-checkbox__label-wrapper .fds-checkbox__box {
	background-color: #6e6e6e;
	color: #FFFFFF;
}

.fds-checkbox.fds-checkbox--disabled .fds-checkbox__label-wrapper .fds-type--checkbox-label {
	background-color: inherit !important;
	color: inherit !important;
}

.station-sharing-accordion {
	/*background-color: #F4F4F4 !important;*/
	/*box-shadow: inset 0 8px 10px 1px rgba(0,0,0,0.04), inset 0 -3px 14px 2px rgba(0,0,0,0.03), inset 0 -2px 5px 0 rgba(0,0,0,0.05) !important;*/
	box-shadow: inset 0 2rem 2rem 0 rgba(0, 0, 0, 0.03);
}

.wallbox-dashboard-80A {
	margin: -1px 0px 0px 0px;
}

.wallbox-dashboard-80A .wallbox-banner {
	float: left;
	width: 100%;
	position: relative;
}

.wallbox-dashboard-80A .wallbox-banner .wallbox-banner-image {
	position: relative;
	text-align: center;
	overflow: hidden;
}

.wallbox-dashboard-80A .wallbox-banner .wallbox-banner-image img {
	width: 100%;
	position: relative;
	display: inline;
}

.wallbox-dashboard-80A .wallbox-banner-status {
	position: absolute;
	width: 100%;
	text-align: center;
	z-index: 1;
}

.wallbox-dashboard-80A .wallbox-banner-status p {
	color: #253C67;
	font-stretch: condensed;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 2px;
	line-height: 24px;
	text-align: center;
}

/*To hide the label for DAY/WEEK/MONTH/YEAR Dropdown in Insights*/
.fds-dropdown .fds-dropdown__label {
	display: none !important;
}

.appstartup-80Awallbox .content {
	top: 10px !important;
}

.text-80A {
	margin: 20px 20px !important;
}

@media only screen and (min-device-width: 100px) and (max-device-width: 320px) {

	.wallbox-dashboard-80A .wallbox-banner-image .rectangle-outer {
		bottom: 77px;
	}

	.back-arrow {
		left: 2% !important;
	}

	.line-copy-wallbox {
		margin: 2% 1% 1% 0% !important;
	}

	.fds-primary-button .fds-primary-button__button span {
		font-size: 1.4rem !important;
	}

	.fmc-button {
		font-size: 1.4rem !important;
	}

	.wallbox-dashboard .wallbox-banner-status, .wallbox-dashboard-80A .wallbox-banner-status {
		top: 40px;
	}

	.wallbox-banner-image .rectangle-locked {
		width: 105px !important;
	}

	.wallbox-banner-image .rectangle-warning {
		width: 105px !important;
	}

	.wallbox-banner-image .rectangle-alert {
		width: 105px !important;
	}

	.wallbox-banner-image .rectangle-unlocked {
		width: 105px !important;
	}

	.wallbox-banner-image .rectangle-charging {
		width: 105px !important;
	}

	.fmc-tabs__tab {
		padding: 1.6rem 0.4rem 3.2rem;
	}

	.start-up {
		bottom: 52px;
	}

	.firmwire_update {
		bottom: 48px !important;
	}

	.fmc-bottom-banner {
		font-size: 11px !important;
	}
}

@media only screen and (min-device-width: 321px) and (max-device-width: 399px) {

	.back-arrow {
		left: 2% !important;
	}

	.line-copy-wallbox {
		margin: 2% 1% 1% 0% !important;
	}

	.fmc-button {
		font-size: 1.5rem !important;
	}

	.wallbox-dashboard .wallbox-banner-status, .wallbox-dashboard-80A .wallbox-banner-status {
		top: 40px;
	}

	.wallbox-banner-image .rectangle-locked {
		width: 119px !important;
	}

	.wallbox-banner-image .rectangle-warning {
		width: 119px !important;
	}

	.wallbox-banner-image .rectangle-alert {
		width: 119px !important;
	}

	.wallbox-banner-image .rectangle-unlocked {
		width: 119px !important;
	}

	.wallbox-banner-image .rectangle-charging {
		width: 119px !important;
	}

	.fmc-tabs__tab {
		padding: 1.6rem 1.0rem 3.2rem;
	}

	.firmwire_update {
		bottom: 74px !important;
	}
}

@media only screen and (min-device-width: 360px) and (max-device-width: 399px) {
	.fds-accordion.fds-color__bg--light .fds-accordion__button * {
		font-size: 19px;
	}
}

@media only screen and (min-device-width: 400px) and (max-device-width: 599px) {
	.wallbox-dashboard-80A .wallbox-banner-image .rectangle-outer {
		bottom: 72px;
	}

	.wallbox-dashboard .wallbox-banner-status, .wallbox-dashboard-80A .wallbox-banner-status {
		top: 30px;
	}

	.wallbox-banner-image .rectangle-locked {
		width: 140px !important;
	}

	.wallbox-banner-image .rectangle-warning {
		width: 140px !important;
	}

	.wallbox-banner-image .rectangle-alert {
		width: 140px !important;
	}

	.wallbox-banner-image .rectangle-unlocked {
		width: 140px !important;
	}

	.wallbox-banner-image .rectangle-charging {
		width: 140px !important;
	}

	.firmwire_update {
		bottom: 76px !important;
	}
}

@media only screen and (min-device-width: 600px) and (max-device-width: 799px) {

	.wallbox-dashboard-80A .wallbox-banner-image .rectangle-outer {
		bottom: 135px;
	}

	.wallbox-dashboard .wallbox-banner-status, .wallbox-dashboard-80A .wallbox-banner-status {
		top: 10px;
	}

	.wallbox-banner-image .rectangle-locked {
		width: 180px !important;
	}

	.wallbox-banner-image .rectangle-warning {
		width: 180px !important;
	}

	.wallbox-banner-image .rectangle-alert {
		width: 180px !important;
	}

	.wallbox-banner-image .rectangle-unlocked {
		width: 180px !important;
	}

	.wallbox-banner-image .rectangle-charging {
		width: 180px !important;
	}

	.firmwire_update {
		bottom: 76px !important;
	}
}

@media only screen and (min-device-width: 800px) and (max-device-width: 1080px) {

	.wallbox-dashboard-80A .wallbox-banner-image .rectangle-outer {
		bottom: 135px;
	}

	.wallbox-dashboard .wallbox-banner-status, .wallbox-dashboard-80A .wallbox-banner-status {
		top: 10px;
	}

	.wallbox-banner-image .rectangle-locked {
		width: 180px !important;
	}

	.wallbox-banner-image .rectangle-warning {
		width: 180px !important;
	}

	.wallbox-banner-image .rectangle-alert {
		width: 180px !important;
	}

	.wallbox-banner-image .rectangle-unlocked {
		width: 180px !important;
	}

	.wallbox-banner-image .rectangle-charging {
		width: 180px !important;
	}

	.firmwire_update {
		bottom: 76px !important;
	}
}

.insightsFilter .p-dropdown .p-dropdown-label {
	margin: auto !important;
	padding-left: 20px !important;
	color: #4D4D4D !important;
	font-family: FordAntenna !important;
	font-size: 14px !important;
	letter-spacing: 1px !important;
	box-sizing: border-box !important;
	height: 52px !important;
	/*width: 299px !important;*/
	width: 100% !important;
	border: 1px solid #4D4D4D !important;
	border-radius: 4px;
	background-color: #FFFFFF !important;

}

.insightsFilter .p-dropdown {
	width: 100% !important;
	margin: 15px 0px;
}

.insightsFilter .p-dropdown-panel .p-dropdown-item {
	margin: auto !important;
	height: 52px !important;
	line-height: 52px !important;
	padding-left: 20px !important;

	color: #4D4D4D !important;
	font-family: FordAntenna !important;
	font-size: 14px !important;
	letter-spacing: 1px !important;
}

.insightsFilter .p-state-highlight {
	background-color: #F0F0F0 !important;
}

.insightsFilter .p-dropdown .p-dropdown-trigger {
	right: 10px !important;
	top: 50%;
	margin-top: -0.5em;
	margin-left: -0.5em;
	position: absolute;
}

.insightsFilter .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
	font-size: 20px !important;
}

.insightsFilter .p-dropdown .p-dropdown-panel {
	box-sizing: border-box !important;
	border: 1px solid #4D4D4D !important;
	border-top: none !important;
	border-radius: 4px !important;
	background-color: #FFFFFF !important;
}

.superscript {
	vertical-align: text-bottom;
	font-size: 10px;
}

.text-center {
	text-align: center;
}

.p-sidebar-bottom {
	height: auto !important;
}

@media only screen and (min-device-width: 410px) and (max-device-width: 950px) {
	.app-setup .content-position {
		height: 82% !important;
	}
	.app-setup .bottom-fixed {
		bottom: 7% !important;
	}
	.app-steps .bottom-fixed {
		bottom: 7% !important;
	}
	.height-padding{
		height: 68px !important;
	}
	.learn-more {
		margin: 16px 0px 10px 0px !important;
	}
}

@media only screen and (min-device-height: 740px) and (max-device-height: 850px) {

	.app-setup .bottom-fixed {
		bottom: 8% !important;
	}
	.app-steps .bottom-fixed {
		bottom: 8% !important;
	}
	.height-padding{
		height: 69px !important;
	}
	.learn-more {
		margin: 16px 0px 10px 0px !important;
	}
	.margin-next-info{
		padding-bottom: 10px !important;
	}
}

@media only screen and (min-device-width: 400px) and (max-device-width: 1080px) {
	.status-alert .message-overlay {
		padding: 0px 0px 50px 0px !important;
	}
}


/*Added for FDS upgrade*/
.fmc-button{
	font-weight: 300;
}

.fmc-text-button {
	width: 100%;
	font-weight: 300;
	text-align: center;
}

/*.fmc-button::after {*/
/*	background-color: #102b4e;*/
/*}*/

:root:root {
	--fds-color--primary: #102b4e;
}

.fmc-accordion {
	--button-padding: 2.6rem;
}

.fmc-text-button:link {
	text-align: center;
}

.fmc-accordion.fmc-accordion--light {
	--body-background-color: var(--fds-color--gray1);
}

.fmc-accordion .fmc-accordion__button {
	background-color: var(--fds-color--gray1);
	font-stretch: condensed;
	font-weight: 300;
}

.fds-transform--uppercase {
	text-transform: uppercase !important;
}

.fmc-accordion .fmc-accordion__button-text {
	font-size: 19px !important;
}

.fmc-accordion .fmc-accordion__panel:not(:last-child) {
	margin-bottom: 0px !important;
}

.fmc-accordion .fmc-accordion__panel.fmc-accordion--expanded .fmc-accordion__body {
	background-color: #f4f4f4;
	box-shadow: inset 0 2rem 2rem 0 rgba(0, 0, 0, 0.03);
}

.fmc-accordion .fmc-accordion__content {
	padding: 2rem 0px;
	margin: 0px 25px;
}

.fmc-toggle {
	width: 100%;
	justify-content: space-between;
}

.fmc-tabs__tablist-wrapper {
	margin: 10px auto 0px auto;
}

.ui-sidebar-bottom.ui-sidebar-sm {
	padding: 0px !important;
}

.fmc-toggle input:checked + .fmc-toggle__track {
	background: linear-gradient(0deg, #0142BD 0%, #0275EB 100%) !important;
	--slider-translate: var(--slider-translate--checked);
}

.fds-font--ford-icons__error::before {
	color: #d62d14 !important;
}

.fds-checkbox .fmc-type--checkbox-label {
	font-size: 1.4rem !important;
	line-height: 1.4rem !important;
	letter-spacing: 0.6px !important;
	padding: 0 !important; ;
}


.fds-text-field__label {
	opacity: inherit !important;
	position: inherit !important;
}

.fds-text-field__input {
	font-family: "FordAntenna", sans-serif;
	font-size: 1.6rem;
	line-height: 2.6rem;
	letter-spacing: .1rem;
	font-weight: 300;
	padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left) !important;
	height: 48px !important;
	border: var(--border-all) !important;
}
.fds-radio .fds-radio__label {
	display: flex;
	align-items: center;
	margin: 0px 0px 0px 20px;
}
.fmc-text-button:focus:not(:focus-visible):hover {
	--text-color: var(--fds-color--primary) !important;
	--text-decoration: none !important;
}

.fmc-text-button:hover {
	--text-color: var(--fds-color--primary) !important;
	--text-decoration: none !important;
}

.fmc-text-button:focus {
	--text-color: var(--fds-color--primary) !important;
	--text-decoration: none !important;
}

a:hover{
	--text-decoration: none !important;
}
.fmc-button[disabled]:focus, .fmc-button[disabled]:hover::after ,.fmc-button[disabled]:focus-visible::after, .fmc-button[disabled]:active  {
	--bg-color: var(--color-disabled) !important;
	--bg-color-hover: var(--color-disabled) !important;
}
.fmc-button:focus, .fmc-button:hover::after ,.fmc-button:focus-visible::after, .fmc-button:active {
	--bg-color: var(--fds-color--primary) !important;
}
.fmc-button[data-dark]:focus {
	--bg-color: var(--fds-color--primary) !important;
	--outline:none;
}
.fmc-text-button {
	--underline-weight: 0rem !important;
	--underline-offset: 0rem !important;
	--underline-color: none !important;
	--underline-color-dark: none !important;
	text-decoration: none !important;
	text-underline-offset: 0 !important;
}
.checkbox-disabled {
	background-color: #6e6e6e !important;
	color: #fff;
}
.checkbox-disabled-label{
	color: var(--fds-color--black) !important;
}

.fmc-tabs__tabpanels {
	width: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	margin: 0 auto;
	flex-direction: column;
}

.fmc-button, .fmc-text-button {
	--cursor : none!important;
	--scale-hover: 1 !important;
}

.fmc-tabs__tab:hover:not([aria-selected=true])::after {
	background-color: transparent !important;
}
